body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Metuo Personal Use-Regular" !important;
  font-size: 1rem;
  line-height: 1.42857143;
  color: #333;
  overflow-x: hidden;
  overflow-y: auto !important;
  background: whitesmoke;
}


@font-face {
  font-family: "Metuo Personal Use-Black";
  font-style: normal;
  font-weight: 900;
  src: url('https://anima-uploads.s3.amazonaws.com/projects/664e4c4c9c55ddf5654a5c88/fonts/metuopersonaluse-black.otf') format("opentype");
}

@font-face {
  font-family: "Gehuropic-Regular";
  font-style: normal;
  font-weight: 400;
  src: url('https://anima-uploads.s3.amazonaws.com/projects/664e4c4c9c55ddf5654a5c88/fonts/gehuropic.otf') format("opentype");
}

@font-face {
  font-family: "Metuo Personal Use-Regular";
  font-style: normal;
  font-weight: 400;
  src: url('https://anima-uploads.s3.amazonaws.com/projects/664e4c4c9c55ddf5654a5c88/fonts/metuopersonaluse-regular.otf') format("opentype");
}

@font-face {
  font-family: "Metuo-Regular";
  font-style: normal;
  font-weight: 400;
  src: url('https://anima-uploads.s3.amazonaws.com/projects/664e4c4c9c55ddf5654a5c88/fonts/metuopersonaluse-regular.otf') format("opentype");
}

@font-face {
  font-family: "Metuo-Bold";
  font-style: normal;
  font-weight: 700;
  src: url('https://anima-uploads.s3.amazonaws.com/projects/664e4c4c9c55ddf5654a5c88/fonts/metuopersonaluse-bold.otf') format("opentype");
}

@font-face {
  font-family: "Metuo Personal Use-Bold";
  font-style: normal;
  font-weight: 700;
  src: url('https://anima-uploads.s3.amazonaws.com/projects/664e4c4c9c55ddf5654a5c88/fonts/metuopersonaluse-bold.otf') format("opentype");
}

a {
  color: black;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
  color: #000000;
}
#root {
  min-height: 100vh;
  background-color: black;
  overflow-x: hidden;
}

:root {
  --bg: #fdfdfd;
  --highlight1: #ed4264;
  --highlight2: #ffedbc;
  --color: #1a1e24;
}

input {
  border: 0 !important;
  border-radius: 10px !important;
}

input[type="file"] {
  max-width: 31vh;
}

button:focus {
  outline: transparent;
}

label {
  color: rgba(34, 41, 91, 0.8);
  font-size: 18px;
  display: flex;
  align-items: center;
  text-align: left;
}

ul {
  margin: 0;
  padding: 0;
}

.radio label {
  display: flex;
  margin-bottom: 0.5rem;
  align-items: center;
  color: #D0A26E;
}
.radio input[type="radio"] {
  position: relative;
  opacity: 0;
}

.radio input[type="radio"] + .radio-label:before {
  content: "";
  background: ghostwhite;
  border-radius: 100%;
  border: 6px solid #D0A26E !important;
  display: inline-block;
  width: 1.2em;
  height: 1.2em;
  position: relative;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
  margin: 6px;
  font-family: Roboto !important;
}
.radio input[type="radio"]:checked + .radio-label:before {
  background-color: #E9C589;
  box-shadow: inset 1 1 1 4px #D0A26E !important;
  font-family: Roboto !important;

}
.radio input[type="radio"]:focus + .radio-label:before {
  outline: none;
  border-color: #D0A26E !important;
  font-family: Roboto !important;

}

.radio input[type="radio"] + .radio-label:empty:before {
  margin-right: 0;
  font-family: Roboto !important;

}

.book_radio {
  background: black;
  padding: 6px 14px;
  border: white 2px solid;
  margin: 10px;
  color: white;
  transform: skewX(-20deg);
  outline: none;
}

.book_session_radio input[type="radio"] {
  display: none;
}

.book_session_radio input[type="radio"]:checked + label {
  border: solid 2px white;
  background: white;
  margin: 10px;
  transform: skewX(-20deg);
  /* border-radius: 4px; */
  color: black;
  outline: none;
}

p {
 font-family: roboto !important;
}

.inputfield {
  width: 100% !important;
}

input[type="file"] {
  margin-left: 24px;
  display: inline-flex;
}

.scrollbar::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}
/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
  background-color: #fff;
}
.scrollbar::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}
/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 5px solid #fff;
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: darkgreen !important;
  border: 4px solid #f4f4f4;
}
/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {
  display: none;
}
.scrollbar {
  width: 25vw;
  min-width: 150px;
  background: #fff;
  overflow-y: scroll;
}
.overflow {
  min-height: 100vh;
  border-color: #000000;
  margin-bottom: 2px;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #080808 !important;
}

[type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}

[type="file"] + label {
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Metuo Personal Use-Regular";
  font-size: inherit;
  font-weight: 600;
  margin-bottom: 1rem;
  outline: none;
  padding: 4px 10px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
}
[type="file"] + label.btn-1 {
  transition: none;
}
[type="file"] + label.btn-1:hover {
  box-shadow: 0 4px;
  top: 2px;
}
[type="file"] + label.btn-2 {
  background-color: gray;
  border-radius: 50px;
  overflow: hidden;
}
[type="file"] + label.btn-2::before {
  color: #fff;
  font-family: "Font Awesome 5 Pro";
  font-size: 100%;
  height: 100%;
  line-height: 3.3;
  position: absolute;
  top: 0px;
  transition: all 0.3s;
}
[type="file"] + label.btn-2:hover {
  background-color: #080808;
}
[type="file"] + label.btn-2:hover::before {
  right: 75%;
}
[type="file"] + label.btn-3 {
  background-color: #497f42;
  border-radius: 0;
  overflow: hidden;
}
[type="file"] + label.btn-3 span {
  display: inline-block;
  height: 100%;
  transition: all 0.3s;
  width: 100%;
}
[type="file"] + label.btn-3::before {
  color: #fff;
  content: "";
  font-family: "Font Awesome 5 Pro";
  height: 100%;
  left: 0;
  line-height: 2.6;
  position: absolute;
  top: -180%;
  transition: all 0.3s;
  width: 100%;
}
[type="file"] + label.btn-3:hover {
  background-color: #ae144f;
}
[type="file"] + label.btn-3:hover span {
  transform: translateY(300%);
}
[type="file"] + label.btn-3:hover::before {
  top: 0;
}
.form-control {
  font-family: Roboto !important;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #D0A26E !important;
  background-color: #000 !important;
  background-clip: padding-box;
  border-bottom: 1px solid #D0A26E !important;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.react-datepicker-wrapper {
  width: 100%;
}
@media only screen and (max-width: 320px) {
  body {
    font-size: 14px !important;
  }
  .radio label {
    font-size: 14px;
  }
  section {
    margin: 0px;
  }
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  section {
    margin: 0px;
  }
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #aaf545;
  fill: #aaf545;
  animation: stroke 2s cubic-bezier(0.65, 0, 0.45, 1) forwards infinite;
}
.form-group div{
  /* width: 100%; */
}
.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 6;
  stroke: #464d63;
  stroke-miterlimit: 10;
  margin: 10px auto;
  box-shadow: inset 0px 0px 0px #ccf381;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke infinite 2s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
.dropdown-item:hover{
  color: #000 !important;
  text-decoration: none;
  background-color: #D0A26E;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
