.logout-logo{
    width:30px;
    height:30px;
}

.nav-bar{
    background-color: white;
}

.blog-link{
    background-color:#C5F595 ;
    font-size: 18px;
    padding: 10px 25px 10px 25px;
    border-radius: 20px;
}

.admin-bowoot{
    text-align: center;
}

.admin-logo{
    top: 0px;
    bottom: 0px;
    margin-left: -20px;
    margin-right:24px;
    margin-top: -8px;
    margin-bottom: -8px;
    box-shadow: 0vh 0vh 1vh rgb(167, 164, 164);
    width:33vh;
}

.navbar-expand .navbar-nav{
    display: flex !important;
    flex-direction: row;
    justify-content: space-between !important;
    width : 100%;
}


.color{
    background-color: white;
    top:2px;
}

.session-div{
    background-color: rgb(250, 250, 250);
    font-size: 13px;
    padding:20px 10px 10px 10px;
    border-radius: 15px;
    color:rgb(148, 145, 145);
}

.session{
   padding:40px 7px 40px 20px;
   display: flex !important;
   flex-direction: row;
   justify-content: space-between !important;
   width : 100%;
}

.trainer-heading{
    padding-left: 20px;
    color:rgb(219, 216, 216) ;
}

.table-table, .table-head, .table-data{
    border: 1px black;
    padding:10px 10px 10px 10px;
    font-size:12px;
    color:rgb(148, 145, 145);
}

.table-table{
    width:20px;
}


.table-row{
    border-bottom: 1px solid rgb(219, 216, 216);
}

.right{
    text-align: right;
}

.right-column, .left-column{
    min-height:90vh;
}

.right-column{
    padding: 10px 0px 10px 10px;
}

.account-logo{
    padding:20px 30px 5px 30px;
    color:rgb(219, 216, 216);
}

.menu{
    padding:10px 10px 2px 60px;
    color:rgb(148, 145, 145);
    cursor: pointer;
}

.logo{
  background-color:#C5F595 ;
  padding:20px 30px 20px 30px;
  border-radius: 20px;
}

.logo-pad{
    padding:100px 20px 20px 70px;
}

.logo_image{
    width: 80px;
    height: 60px;
}

.version{
    letter-spacing: 2px;
    padding-left: 40px;
    font-weight: bold;
}

.render{
    padding:30px 30px 30px 30px;
}

.dashboard-logo{
    font-size: 25px;
    font-weight: bold;
    color: rgb(180, 171, 204);
}

.counts{
    padding:30px 0px 30px 30px;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between !important;
    width : 100%;
}

.row2{
    padding:0px 0px 30px 30px;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between !important;
    width : 100%;
}

.trainer-count{
    background-color: white;
    border-radius: 20px;
    padding:20px 20px 20px 20px;
    font-size:16px;
    color:rgb(148, 145, 145);
}

.request{
    background-color: white;
    border:0;
    box-shadow:0px 0px 2px rgb(219, 216, 216);
}

.space{
    font-weight: bold;
}

.trainer_name{
    color:black;
    padding-left: 40px;
}

.scrolls{
    min-height: 90vh;
}

.container-service {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between !important;
  }

  .hideButton{
    display: none;
}

#open{
    display:block;
}

#hidden{
    display:none;
}

.details-container {
    background: white;
    padding: 25px;
    border-radius: 20px;
    box-shadow: 10px 9px 16px #d8e2ef;
    margin: 5px;
    border: 1px solid rgba(0, 128, 0, 0.2);
  }

  .view-container{
      background-color: rgb(250, 250, 250);
      border:0;
  }

  .view-con{
    background-color: white;
    padding:20px 20px 20px 20px;
  }

  .padding-top{
    padding-top: 30px;
  }

  .star{
      display: inline;
      font-size: 14px;
  }

  .pd{
      padding-left:30px;
      color:rgb(148, 145, 145) ;
      font-family: 'roboto';
  }

  .right-side{
    display: flex !important;
    flex-direction: row;
    justify-content: space-between !important;
  }

  .wid{
      width:500px;
  }

  .wid1{
      width:800px;
  }

  .view-conn{
    background-color: white;
    padding:40px 50px 40px 50px;
  }

  .tdata{
      padding:20px 20px 20px 20px;
      background-color: rgba(247, 240, 238, 0.863);
      border-radius: 10px;
      font-size: 14px;
      font-family: 'roboto';
  }
  
  .btn-class{
    border-radius:20px;
    padding:4px 20px 4px 20px;
    color:white;
    font-size: 14px;
  }

  .table-service{
    width:1180px;
    color: black;
    font-size: 16px;
  }

  .right-data{
      text-align: right;
  }

  .service-row{
      border-bottom: 20px solid white;
      background-color: rgba(194, 203, 233, 0.253);
      padding:30px 30px 30px 30px;
  }

  .table-padding{
      padding:15px 20px 10px 20px;
  }

  .hidden{
    padding:0px 60px 0px 60px;
    min-width: 900px;
  }

@media screen and (min-width: 321px) and (max-width: 375px) {

    .hideButton{
        display: block;
    }

    #open{
        display:none;
    }

     .right-column{
         min-height: 0vh;
         padding:30px 30px 30px 30px;
     }
     .session{
         padding: 10px 10px 30px 15px;
     }

     .scrolls{
        min-height: 0vh;
    }
    
      .logo{
        padding:20px 30px 20px 30px;
        border-radius: 20px;
      }
      
      .logo-pad{
          padding:20px 20px 20px 100px;
      }
      
      .logo_image{
          width: 80px;
          height: 60px;
      }

      .container-service {
        padding-left: 40px;
        display: flex;
        flex-direction: column;
        text-align: center;
      }
      .trainer_name{
        padding-left: 0px;
    }

    .more{
        padding-top: 20px;
        padding-left: 75px;
    }

    .blogs-link{
        padding-top:14px;
        margin-left: -4vh;
    }

    .admin-logo{
        width:15vh;
    }

    .power_button{
        margin-left: -4vh;
        padding-top: 4px;
    }

 }

 @media only screen and (min-width: 376px) and (max-width: 425px) {
    .hideButton{
        display: block;
        text-align: right;
    }

    #open{
        display:none;
    }

     .right-column{
         min-height: 0vh;
         padding:50px 50px 50px 50px;
     }

     .session{
         padding: 10px 10px 30px 15px;
     }

     .scrolls{
        min-height: 0vh;
    }
    
      .logo{
        padding:20px 30px 20px 30px;
        border-radius: 20px;
      }
      
      .logo-pad{
          padding:20px 20px 20px 100px;
      }
      
      .logo_image{
          width: 80px;
          height: 60px;
      }

      .container-service {
        padding-left: 40px;
        display: flex;
        flex-direction: column;
        text-align: center;
      }
      .trainer_name{
        padding-left: 0px;
    }

    .more{
        padding-top: 20px;
        padding-left: 100px;
    }

    .blogs-link{
        padding-top:14px;
        margin-left: -4vh;
    }

    .admin-logo{
        width:15vh;
    }

    .power_button{
        margin-left: -4vh;
        padding-top: 4px;
    }
 }

 
 @media only screen and (min-width: 426px) and (max-width: 572px) {
    .hideButton{
        display: block;
        text-align: right;
    }

    #open{
        display:none;
    }

     .right-column{
         min-height: 0vh;
         padding:50px 50px 50px 50px;
     }

     table,th,td{
         font-size: 16px;
     }

     .session{
         padding: 10px 10px 30px 15px;
     }

     .scrolls{
        min-height: 0vh;
    }
    
      .logo{
        padding:20px 30px 20px 30px;
        border-radius: 20px;
      }
      
      .logo-pad{
          padding:20px 20px 20px 100px;
      }
      
      .logo_image{
          width: 80px;
          height: 60px;
      }

      .container-service {
        padding-left: 30px;
        display: flex;
        flex-direction: column;
        text-align: center;
      }
      .trainer_name{
        padding-left: 0px;
    }

    .more{
        padding-top: 20px;
        padding-left: 140px;
    }

    .blogs-link{
        padding-top:14px;
        margin-left: -4vh;
    }

    .admin-logo{
        width:15vh;
    }

    .power_button{
        margin-left: -4vh;
        padding-top: 4px;
    }
 }


 @media only screen and (min-width: 573px) and (max-width: 573px) {
    .hideButton{
        display: block;
        text-align: right;
    }

    #open{
        display:none;
    }

     .right-column{
         min-height: 0vh;
         min-width: 610px;
         padding:50px 50px 50px 50px;
     }

     table,th,td{
         font-size: 16px;
     }

     .session{
         padding: 10px 10px 30px 15px;
     }

     .scrolls{
        min-height: 0vh;
    }
    
      .logo{
        padding:20px 30px 20px 30px;
        border-radius: 20px;
      }
      
      .logo-pad{
          padding:20px 20px 20px 100px;
      }
      
      .logo_image{
          width: 80px;
          height: 60px;
      }

      .container-service {
        padding-left: 30px;
        display: flex;
        flex-direction: column;
        text-align: center;
      }
      .trainer_name{
        padding-left: 0px;
    }

    .more{
        padding-top: 20px;
        padding-left: 210px;
    }

    .blogs-link{
        padding-top:14px;
        margin-left: -4vh;
    }

    .admin-logo{
        width:15vh;
    }

    .power_button{
        margin-left: -4vh;
        padding-top: 4px;
    }
 }
