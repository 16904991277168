/*
  	Flaticon icon font: Flaticon
  	Creation date: 22/06/2016 15:01
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
    url("./Flaticon.woff") format("woff"),
    url("./Flaticon.ttf") format("truetype"),
    url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-size: 158px;
  font-weight: 600;
  margin-left: 20px;
  color: yellowgreen;
}

.fa-facebook-square .flaticon-4-weights-on-bar:before {
  content: "\f100";
}
.flaticon-abdominal-exercise:before {
  content: "\f101";
}
.flaticon-apple:before {
  content: "\f102";
}
.flaticon-apple-measure:before {
  content: "\f103";
}
.flaticon-bottle-opener:before {
  content: "\f104";
}
.flaticon-bottle-with-heart:before {
  content: "\f105";
}
.flaticon-dancer-outline:before {
  content: "\f106";
}
.flaticon-exercise-bike:before {
  content: "\f107";
}
.flaticon-flexible-man:before {
  content: "\f108";
}
.flaticon-footprints-on-a-scale:before {
  content: "\f109";
}
.flaticon-gymnast-posture:before {
  content: "\f10a";
}
.flaticon-heart-beats-controller-outline:before {
  content: "\f10b";
}
.flaticon-heart-pulses:before {
  content: "\f10c";
}
.flaticon-heart-pulses-1:before {
  content: "\f10d";
}
.flaticon-jar-of-food:before {
  content: "\f10e";
}
.flaticon-medical-diet-page:before {
  content: "\f10f";
}
.flaticon-meditation-yoga-posture:before {
  content: "\f110";
}
.flaticon-meter-spiral:before {
  content: "\f111";
}
.flaticon-pills-jar:before {
  content: "\f112";
}
.flaticon-reduce-waist:before {
  content: "\f113";
}
.flaticon-running-on-machine:before {
  content: "\f114";
}
.flaticon-shoe:before {
  content: "\f115";
}
.flaticon-skipping-rope:before {
  content: "\f116";
}
.flaticon-slipers:before {
  content: "\f117";
}
.flaticon-somersault-athlete:before {
  content: "\f118";
}
.flaticon-sportive-chronometer:before {
  content: "\f119";
}
.flaticon-stopwatch:before {
  content: "\f11a";
}
.flaticon-striped-ball:before {
  content: "\f11b";
}
.flaticon-strong-heart:before {
  content: "\f11c";
}
.flaticon-sweets-vs-fruits:before {
  content: "\f11d";
}
.flaticon-two-gym-dumbbells:before {
  content: "\f11e";
}
.flaticon-underwear:before {
  content: "\f11f";
}
.flaticon-warming-up:before {
  content: "\f120";
}
.flaticon-weight:before {
  content: "\f121";
}
.flaticon-weight-control:before {
  content: "\f122";
}
.flaticon-weights:before {
  content: "\f123";
}
.flaticon-whistles:before {
  content: "\f124";
}
.flaticon-women-summer-pijama:before {
  content: "\f125";
}
.flaticon-yin-yang:before {
  content: "\f126";
}
.flaticon-yoga-lotus-posture:before {
  content: "\f127";
}
