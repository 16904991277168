.inputfieldatfitnessform {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 20px;
  align-items: baseline;
}
.form-group-goal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 2rem !important;
  width: 200px;
}
.marginfitness {
  margin-bottom: 20px;
  background: #c5d0d661 !important;
}

.leftmarginfitness {
  margin-left: 15px;
}
.marginforbutton {
  margin-bottom: 100px;
}
.enroll_buttonforfitness {
  font-size: 20px;
  font-weight: 600;
  padding: 10px 18px;
  background-color: #E9C589;
  color: black;
}
.inputfieldatfitnessform > .btnColor {
  background: gainsboro;
}

.inHome,
.inGym,
.low,
.sedantary,
.moderate {
  background: #E9C589 !important;
  color: black !important;
}

.imagefitnessform {
  height: 250px;

  weight: 250px;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .imagefitnessform {
    display: none;
  }
}
@media only screen and (max-width: 320px) {
  .enroll_buttonforfitness {
    margin-bottom: 40px;
  }

  .radio label {
    font-size: 17px !important;
  }
}
@media only screen and (max-width: 375px) {
  .enroll_buttonforfitness {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 425px) {
  .enroll_buttonforfitness {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 768px) {
  .enroll_buttonforfitness {
    margin-bottom: 100px;
  }
  .workoutpreference {
    font-size: 15px;
  }
  .dailylifestyle {
    font-size: 15px;
  }
  .medicalcondition {
    font-size: 15px;
  }
  .wanttoacheievegoal {
    font-size: 15px;
  }
  .whendidulastworkout {
    font-size: 15px;
  }
  .marginformyself {
    flex: 0 0 50%;
    max-width: 100% !important;
  }
  /* .col-sm-6 {
    flex: 0 0 50%;
    max-width: 100% !important;
  } */
}
