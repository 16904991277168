.corporate_background {
  display: flex;
  flex-direction: column;
}
.corporate_image {
  height: 100%;

  background: url("../assests/expert.png") center rgba(255, 255, 255, 0.6)
    no-repeat;
  background-size: contain;
}
.trainer-container {
  background-color: "white";
}
.inputfieldsize {
  display: flex;
  justify-content: center;
  margin: 40px;
}
.textfieldsize {
  display: flex;
  justify-content: space-between;
  margin-left: 50px;
  margin-bottom: 20px;
}
.natalheading {
  color: rgba(60, 68, 111, 0.6);
  display: flex;
  justify-content: center;
}
.message_container {
  background-color: orange;
  word-break: break-word;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  padding: 20px;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 600;
  color: rgba(51, 51, 51, 0.8);
  font-family: "Roboto" !important;
}
.corporateplanheader {
  color: rgba(60, 68, 111, 0.6);
  display: flex;
  justify-content: center;
}
.MuiInputBase-input {
  font-weight: 500 !important;
  color: #D0A26E !important;
}
.MuiFormLabel-root {
  font-weight: 400 !important;
  color: #D0A26E !important;
  border-bottom: 1px solid #D0A26E !important;
}
.MuiFormLabel-root.Mui-focused {
  font-weight: 400 !important;
  color: #D0A26E !important;
}

@media only screen and (max-width: 320px) {
  .buttonsubmit {
    background-color: #E9C589;
    border-radius: 0.5rem;
    font-family: Arial, Helvetica, sans-serif;
    padding: 11px;
    top: 50%;
  }
}

@media only screen and (max-width: 320px) {
  .modal-dialog {
    /* height: -webkit-fill-available; */
  }
  .MuiInputBase-input {
    width: auto !important;
  }
}
@media only screen and (max-width: 321px) and (max-width: 769px) {
}
