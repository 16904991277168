.privacyContainer {
  text-align: justify;
font-family: "Roboto" !important;
  color: #E9C589;
}
.formarginbottom {
  margin-bottom: 2rem;
}

.topMargin {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.privacyImage {
  width: 20%;
  height: 20%;
  margin-top: 4rem;
}

@media only screen and (max-width: 320px) {
  .topMargin {
    flex-direction: column;
  }
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .topMargin {
    flex-direction: column;
  }
}

@media only screen and (max-width: 320px) {
  .privacyImage {
    width: 50%;
    margin-left: 70px;
  }
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .privacyImage {
    width: 40%;
    margin-left: 200px;
  }
}

@media only screen and (max-width: 320px) {
  .termsHeader {
    font-size: larger;
    font-weight: bolder;
    color: #E9C589;

  }
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .termsHeader {
    font-size: xx-large;
    font-weight: bolder;
  }
}
.TermsCenter {
  margin: 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .TermsCenter {
    margin: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.topParagraph {
  color: #E9C589;
  font-size: 1.5rem;
  width: 65%;
  text-align: justify;
  word-break: break-word;
  font-family: "Roboto";
}
@media only screen and (max-width: 320px) {
  .topParagraph {
    font-size: 15px;
    width: 100%;
    text-align: justify;
    word-break: break-word;
  }
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .topParagraph {
    font-size: 18px;
    text-align: justify;
    word-break: break-word;
    align-items: center;
  }
}
@media only screen and (max-width: 320px) {
  .termsIndiaHeading {
    font-size: large;
    text-align: center;
  }
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .termsIndiaHeading {
    text-align: center;
  }
}
@media only screen and (max-width: 320px) {
  .feedback {
    font-size: 14px;
  font-family: Roboto !important;
  color: #E9C589;
  }
}
