.marginHeaderDiet {
  margin-right: 20px;
  color: #E9C589 !important;
}
.PackageDetails_Header {
  background: black;
  color: #E9C589;
  padding: 20px;
  border-radius: 4px 4px 0 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.Acknowledgement_Header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background: black;
  color: #E9C589;
  padding: 20px;
  border-radius: 4px 4px 0 0;
}
