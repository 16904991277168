.readSuccessStoriesHeading {
  font-size: 50px;
  font-weight: bolder;
  color: #3c446f;
  line-height: 55px;
}
.ReadReviewparagragh {
  color: #3c446f;
  font-weight: 20px;
  margin-top: 15px;
}
.testheading {
  display: flex;
  align-items: center;
}
.testCard {
  display: flex;
  align-items: center;
  background-color: ghostwhite;
  border: 0 !important;
  justify-content: flex-start;
}
.testCardContent {
  justify-content: space-between;
  display: flex;
}
.testCardText {
  text-align: justify;
  color: #3c446f;
  margin-top: 0px;
  justify-content: flex-start;
  display: flex;
}

.testCardImg {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50% !important;
  border: 0px !important;
}
.quotationMark {
  font-size: 75px;
  color: #3c446f;
  margin-top: 0px;
}
.testCardName {
  color: #3c446f;
  font-size: 25px;
  justify-content: flex-start;
  display: flex;
}
.testCardOccupation {
  color: #3c446f;
  justify-content: flex-start;
  display: flex;
}
.testBtn {
  text-decoration: none;
  margin-top: 50px;
  background-color: #ccf381;
  color: #3c446f;
  display: inline-block;
  border: 0 !important;
  border-radius: 14px;
  padding: 16px 10px;
}

.testBtn:hover {
  font-weight: bold;
}
.dashboard-container {
  text-align: center;
}

.container-fluid .verticle-slider {
  width: 100%;
  background: ghostwhite;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.container-fluid .verticle-slider div.carousel-wrapper {
  height: 50vh;
  overflow: hidden;
}
div.container-fluid .verticle-slider div.carousel-wrapper .owl-carousel {
  height: 100%;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  margin-top: 100px;
}
div.container-fluid .verticle-slider div.carousel-wrapper .owl-carousel .item {
  width: 400px;
  height: 400px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
}
div.container-fluid
  .verticle-slider
  div.carousel-wrapper
  .owl-carousel
  .item
  > div {
  color: black;
}
/* CSS talk bubble */
.talk-bubble {
  margin: 40px;
  display: inline-block;
  position: relative;
  width: 200px;
  height: auto;
  background-color: lightyellow;
}
.border {
  border: 8px solid #666;
}
.round {
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
}

/* Right triangle placed top left flush. */
.tri-right.border.left-top:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -40px;
  right: auto;
  top: -8px;
  bottom: auto;
  border: 32px solid;
  border-color: #666 transparent transparent transparent;
}
.tri-right.left-top:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -20px;
  right: auto;
  top: 0px;
  bottom: auto;
  border: 22px solid;
  border-color: lightyellow transparent transparent transparent;
}

/* Right triangle, left side slightly down */
.tri-right.border.left-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -40px;
  right: auto;
  top: 30px;
  bottom: auto;
  border: 20px solid;
  border-color: #666 #666 transparent transparent;
}
.tri-right.left-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -20px;
  right: auto;
  top: 38px;
  bottom: auto;
  border: 12px solid;
  border-color: lightyellow lightyellow transparent transparent;
}

/*Right triangle, placed bottom left side slightly in*/
.tri-right.border.btm-left:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -8px;
  right: auto;
  top: auto;
  bottom: -40px;
  border: 32px solid;
  border-color: transparent transparent transparent #666;
}
.tri-right.btm-left:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 0px;
  right: auto;
  top: auto;
  bottom: -20px;
  border: 22px solid;
  border-color: transparent transparent transparent lightyellow;
}

/*Right triangle, placed bottom left side slightly in*/
.tri-right.border.btm-left-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 30px;
  right: auto;
  top: auto;
  bottom: -40px;
  border: 20px solid;
  border-color: #666 transparent transparent #666;
}
.tri-right.btm-left-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 38px;
  right: auto;
  top: auto;
  bottom: -20px;
  border: 12px solid;
  border-color: lightyellow transparent transparent lightyellow;
}

/*Right triangle, placed bottom right side slightly in*/
.tri-right.border.btm-right-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 30px;
  bottom: -40px;
  border: 20px solid;
  border-color: #666 #666 transparent transparent;
}
.tri-right.btm-right-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 38px;
  bottom: -20px;
  border: 12px solid;
  border-color: lightyellow lightyellow transparent transparent;
}
.tri-right.border.btm-right:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -8px;
  bottom: -40px;
  border: 20px solid;
  border-color: #666 #666 transparent transparent;
}
.tri-right.btm-right:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 0px;
  bottom: -20px;
  border: 12px solid;
  border-color: lightyellow lightyellow transparent transparent;
}
/* Right triangle, right side slightly down*/
.tri-right.border.right-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -40px;
  top: 30px;
  bottom: auto;
  border: 20px solid;
  border-color: #666 transparent transparent #666;
}
.tri-right.right-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -20px;
  top: 38px;
  bottom: auto;
  border: 12px solid;
  border-color: lightyellow transparent transparent lightyellow;
}

/* Right triangle placed top right flush. */
.tri-right.border.right-top:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -40px;
  top: -8px;
  bottom: auto;
  border: 32px solid;
  border-color: #666 transparent transparent transparent;
}
.tri-right.right-top:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -20px;
  top: 0px;
  bottom: auto;
  border: 20px solid;
  border-color: lightyellow transparent transparent transparent;
}

/* talk bubble contents */
.talktext {
  padding: 1em;
  text-align: left;
  line-height: 1.5em;
}
.talktext p {
  /* remove webkit p margins */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  background-color: whitesmoke;
  color: #3c446f;
  display: inline-block;
  border: none;
}
.scroll {
  height: 600px;
  overflow: hidden;
  overflow-y: scroll;
}
@media only screen and (max-width: 320px) {
  .testimonials {
    flex-direction: column !important;
  }
  .readSuccessStoriesHeading {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 321px) and (max-width: 426px) {
  .testimonials {
    flex-direction: column !important;
  }
  .readSuccessStoriesHeading {
    font-size: 1.5rem;
  }
}
