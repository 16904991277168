.packagedetails {
  text-align: left;
  text-align: justify;
  margin: 15px;
}
.packagePlan {
  font-weight: bold;
  margin: 15px;
}
.packageSummary {
  text-align: center;
  font-weight: bold;
  margin: 15px;
  color: #E9C589 !important;
}
.packageamount {
  font-weight: bold;
  color: #E9C589 !important;
  font-family: Roboto !important;
}
.radioforpackage {
  display: flex;
  justify-content: center;
}
.packagebutton {
  display: flex;
  justify-content: space-between;
}
.packageMonth {
  display: flex;
  justify-content: space-between;
  color: #333;
  flex-direction: row;
  font-weight: 600;
  font-size: 14px;
  font-family: Roboto !important;
}
.servicegap {
  display: flex;
  flex-direction: row;
  margin: 10px;
  justify-content: space-between;
}
.servicecolor {
  color: #E9C589 !important;
  font-weight: bold;
}
.descriptiongap {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  color: #E9C589;
  font-family: Roboto !important;
}
.amountgap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
  color: #E9C589 !important;

}
.servicebold {
  font-weight: bold;
  color: #E9C589 !important;

}
.subtotal {
  font-weight: bold;
  color: #E9C589 !important;

}
.howitcomponent_buttoncancel {
  font-size: 16px;
  font-weight: 600;
  padding: 10px 18px;
  border-radius: 4px;
  background-color: #E9C589;
  border-color: transparent;
  color: black;
  margin: 10px;
}
.howitcomponent_buttonproceed {
  font-size: 16px;
  font-weight: 600;
  padding: 10px 18px;
  border-radius: 4px;
  background-color: #E9C589;
  border-color: transparent;
  color: black;
  margin: 10px;
}
.alignmentforradio {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
@media only screen and (max-width: 320px) {
  .howitcomponent_buttoncancel {
    display: flex;
    justify-content: space-between;
    width: auto;
    height: 50%;
  }
  .packagedetails {
    display: flex;
    width: 100%;
    height: 30%;
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 0px;

    padding: 0px;
  }
  .amountgap {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .servicegap {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .packageSummary {
    margin: 0px;
  }
  .modal-dialog {
    margin: auto;
  }
  hr {
    display: none;
  }
}
@media only screen and (max-width: 375px) {
  .packagedetails {
    display: flex;
    width: 100%;
    height: 30%;
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 50px;
    margin-right: 60px;
    padding: 0px;
  }
  .amountgap {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .servicegap {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .packageSummary {
    margin: 0px;
  }
  .modal-dialog {
    margin: auto;
  }
  hr {
    display: none;
  }
}
@media only screen and (max-width: 425px) {
  .packagedetails {
    display: flex;
    width: 100%;
    height: 30%;
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 50px;
    margin-right: 60px;
    padding: 0px;
  }
  .amountgap {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .servicegap {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .packageSummary {
    margin: 0px;
  }
  .modal-dialog {
    margin: auto;
  }
  hr {
    display: none;
  }
}
