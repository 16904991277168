.fitnesscard {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px;
}
.fitnesstrainingbutton {
  color: yellowgreen;
  border-radius: 0rem;
  border: none;
  /* color: white;
  padding: 15px 32px;
  
  display: inline-block;
  font-size: 16px; */

  text-decoration: none;
  cursor: pointer;
}

.productclosetab {
  margin-left: 50px;
  cursor: pointer;
}

.btnmodal {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 15px 32px;

  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-right: 250px;
  cursor: pointer;
}
.knowyourbody {
  text-align: center;
}

.productsinfo {
  width: 40rem;
}

.howitworks {
  top: 170px;
  position: fixed;
  right: 0;
  z-index: 99;
  width: 500px;
}

.modal-dialog {
  top: 50% !important;
  left: 50% !important;
  position: fixed;
  width: 95%;
}
.enroll-now-container {
  display: flex;
  flex-direction: row;
  text-align: left;
  font-size: 14px;
  line-height: 1.5rem;
  word-break: break-word;
  font-family: Roboto !important;
}

.alignheaderfitness {
  display: block;
  justify-content: space-between;
  width: 100%;
}
.pricealignmentfitness {
  display: block;
  font-family: 'Roboto';
}

.marginbwpricefitness {
  margin-right: 2rem;
  font-family: Roboto !important;
}
.arrowSize {
  font-size: 15px !important;
  cursor: pointer !important;
}
.fitnesstraininginfobtn {
  background-color: white;

  border-radius: 4rem;
  border: none;
  /* border: none;

  padding: 15px 32px; */
  color: yellowgreen;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px 0px 480px;
  cursor: pointer;
}
.productclosetab {
  margin-left: 50px;
  cursor: pointer;
}
.dott {
  background-color: #E9C589;
  border-radius: 50%;
  display: flex;
  box-shadow: 8px 9px 16px #d8e2ef;
}

fa-arrow-right {
  height: 50px;
  width: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.modal-fitnessTraining {
  background-color: whitesmoke;
  width: 50px;
  height: 50px;
}
.priceandbuttonfitnesstraining {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #E9C589;
  border-radius: 0 0 20px 20px;

  padding: 0 10px 0px 10px;
}
.fitnesstrainingmaincontainer {
  display: "flex";
}

.diet_background {
  /* background: url("../assests/diet_landing.png") center no-repeat; */
  display: block;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 20%;
  background-size: 100%;
}
.diet_2_card_mq{
  margin-left: 128px;
  margin-top: 125px;
}
@media only screen and (max-width: 678px) {
  .howitworks {
    display: flex;
    flex-direction: column;
    position: static;
    max-width: fit-content;
  }
  .knowyourbody {
    font-size: 16px;
  }
  .productsinfo {
    width: 18rem;
  }
  .fitnessTrainingbtn {
    padding: 7px 16px !important;
    font-size: 14px !important;
    margin: auto !important;
  }
  .fitnesscard {
    width: 18rem;
  }
  .diet_2_img_mq {
    width: 40% !important;
    margin-right: 5px;
  }
}

@media only screen and (max-width: 769px) {
  .howitworks {
    display: flex;
    flex-direction: column;
    position: static;
    max-width: fit-content;
    margin: auto;
  }
  .diet_2_img_mq {
    width: 50% !important;
    margin-right: 10px;
  }
  .diet_2_card_mq {
    margin: auto;
  }
}

@media only screen and (max-width: 320px) {
  .howitworks {
    display: flex;
    flex-direction: column;
    position: static;
    max-width: fit-content;
    margin: auto;
  }
}
@media only screen and (min-width: 1440px) {
  .howitworks {
    top: 260px;
    position: fixed;
    right: 0;
    z-index: 99;
    width: 500px;
    margin-right: 40px;
  }
}

.close {
  color: #E9C589 !important;
  font-weight: 600;
  font-size: 32px;
}

.close:hover {
  color: #E9C589 !important;
}
