.fa {
  padding: 0px;
  font-size: 25px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.fa-facebook-square {
  background: #3b5998;
  color: white;
}
.login {
  background-image: url("./../assests/banner-back.png");
  display: flex;
  justify-content: center;
  background-size: cover;
  flex-direction: column;
}
.login_background {
  background-size: contain;
  min-height: 70vh;
}

.login_signup_button {
  font-size: 20px;
  font-weight: 600;
  padding: 10px 18px;
  border-radius: 10px;
  background-color: #aaf545;
  border-color: transparent;
  color: black;
}

@media only screen and (max-width: 320px) {
  .fa-facebook-square {
    margin-left: auto;
  }
}
.fa-google-plus {
  background: red;
  color: white;
}

.NavItem {
  background-color: brown;
}
.fa-arrow-circle-right {
  font-size: 50px;
}

.fa-long-arrow-right {
  background: #E9C589;
  color: white;
}
.btn {
  border-block-end-style: none;
}

a {
  text-decoration: none !important;
}
.modal {
  text-align: center;
  padding: 0 !important;
  /* background-color: ghostwhite; */
}
.OrloginWith {
  text-align: center;
}
.modal-dialog {
  position: absolute;
  left: 50%;
  top: 40%;
  min-width: 25vw;
  transform: translate(-50%, -50%) !important;
}

.modal-content {
  align-items: center;
  background: black;
  border: 2px solid transparent;
}
@media only screen and (max-width: 320px) {
  .modal-container {
    width: 263px;
    margin-left: 20px;
  }
}
@media only screen and (max-width: 375px) {
  .modal-container {
    width: 2100px;
    margin-left: 40px;
  }
}
@media only screen and (max-width: 425px) {
  .modal-container {
    width: 2000px;
    margin-left: 55px;
  }
}

@media only screen and (min-width: 426px) and (max-width: 769px) {
  .modal-container {
    margin: auto;

    display: inline-flex;
    inline-size: max-content;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-height: max-content;
    max-width: max-content;
  }
}

.roundbtn {
  display: flex;
  justify-content: center;
  line-height: 0%;
}
.nav-tabs {
  display: flex;
  justify-content: space-between;
  width: auto;
}

.modal-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: black;
  border-radius: 20px;
  border: 0px solid;
  text-align: center;
}
@media only screen and (max-width: 320px) {
  .modal-container {
    width: 265px;
  }
}
.tabs {
  display: flex;
  justify-content: space-between;
  font-weight: bolder;
  font-size: 23px;
  color: lightgray;
}

.nav-link {
  cursor: pointer;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: 0 !important;
}
.editprofile {
  margin: auto;
  width: 70vh;
}
.editprofileHeader {
  color: rgba(60, 68, 111, 0.6);
}
.edit_allign {
  display: flex;
  align-items: flex-start;
}
.paddingeditcontainer {
  padding-right: 120px;
}
@media only screen and (min-width: 321px) and (max-width: 767px) {
  .login_background {
    display: none;
  }
}
