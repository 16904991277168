.bg-header {
  width: 100%;
}
.logo-bg {
  background: #E9C589;
  border-radius: 0 50px 50px 0;
  margin: 10px;
  font-size: 3rem;
  color: #080808;
  padding: 10px !important;
  margin: 0;
  align-items: center;
  width: 100%;
  display: flex;
}
.dropdown-item {
  /* display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400; */
    color: #D0A26E !important;
    /* text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0; */
}
.dropdown-menu {
    color: #D0A26E !important;
    background-color: #000 !important;
}
.navbar { 
  padding: .5rem 1rem !important;
}
.navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
}

@media only screen and (max-width: 320px) {
  .logo-bg {
    font-size: 28px;
    padding: 5px;
    max-width: fit-content;
  }
  .navbar-light {
    display: flex;
    flex-direction: row-reverse;
  }
  .bowoot {
    font-size: 34px !important;
    padding: 5px !important;
  }
  .bowootheadericon {
    margin-right: 6px !important;
  }
}

@media only screen and (min-width: 321px) and (max-width: 375px) {
  .bowoot {
    font-size: 34px !important;
    padding: 5px !important;
  }
  .bowootheadericon {
    width: 2rem !important;
    margin-right: 6px !important;
  }
}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .bowoot {
    font-size: 34px !important;
    padding: 5px !important;
  }
  .bowootheadericon {
    /* width: 14rem!important; */
    margin-right: 6px !important;
  }
  .navbar-expand-lg .navbar-collapse {
    margin: 0px !important;
  }
}
.bowootheadericon {
  margin-right: 6px;
}
.bowoot {
  top: 0px;
  color: #080808;
  font-size: 2.4rem;
  margin: 0;
  display: flex;
  align-items: center;
}
.custom {
  display: flex !important;
  width: 100%;
  padding: 0 30px;
  flex-direction: row;
}

.home {
  font-family: "Fredoka One", cursive;
  font-size: 3.2rem;
  font-weight: 400;
}

.servie-link {
  font-weight: 500;
  font-size: 17px;
  text-transform: uppercase;
}

.dropdown > .dropdown-menu,
.show {
  border-radius: 14px !important;
}

.dropdown > .dropdown-menu,
.show > a {
  margin: 10px 0;
  text-align: center;
}

.custom-navbar-color {
  position: fixed !important;
  z-index: 999;
  background: #000 !important;
}
.home5 {
  text-align: left;
}

.navbar-light .navbar-nav .nav-link {
  color: #D0A26E !important;
  text-align: center;
}
.navbar-expand-lg .navbar-collapse {
  margin: 20px;
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .logo-bg {
    font-size: 2rem;
  }
  .bowoot {
    font-size: 1.4rem;
    padding: 0;
  }
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(233,197,137)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
}
