@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,
  700,
  300);
@import url(https://fonts.googleapis.com/css?family=Gochi+Hand|Roboto:700);
#lion {
  width: 40%;
  height: 60%;
}
.foot {
  padding: 3rem 0;
}

.text-color {
  color: rgb(208, 162, 110);
}

.our-customer-img {
  width: -webkit-fill-available;
}

.How-it-works,
.our-customer {
  padding: 1rem 0;
}

.modal-content {
  box-shadow: none !important;
}
.modal-header {
  margin-left: auto !important;
}
.items {
  text-align: center;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.bowootfoot {
  text-align: center;
  display: flex;
  flex-direction: column;
  text-align: justify;
}
.faqfoot {
  text-align: center;
  display: flex;
  flex-direction: column;
  text-align: justify;
}
.corporatefoot {
  text-align: center;
  display: flex;
  flex-direction: column;
  text-align: justify;
}
.servicesfoot {
  text-align: center;
  display: flex;
  flex-direction: column;

  text-align: justify;
}
.itemsfoot {
  color: white;
}
@media only screen and (max-width: 320px) {
  .servicesfoot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .faqfoot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .corporatefoot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .bowootfoot {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .itemsfoot {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .service_mq_foot{
    margin-left: 84px;
  }
  .faq{
    text-align: center;
  }
  .faq_item_mq{
    margin-left: 34px;
  }
  .makeinindia{
    margin-left: auto;
  }
  .corporate_mq{
    margin-left: 30px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 769px) {
  .bowootfoot {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .corporatefoot {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .faqfoot {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .servicesfoot {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .containerMakeInIndia {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .makeinindia {
    text-align: left;
  }
}
@media only screen and (min-width: 321px) and (max-width:426px){
  .servicesfoot {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .faqfoot {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .corporatefoot {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .bowootfoot {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .itemsfoot {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .faq{
    text-align: center;
  }
  /* .faq_item_mq{
    margin-left: 34px;
  } */
  .makeinindia{
    margin-left: 36px;
  }
  /* .corporate_mq{
    margin-left: 30px;
  } */
}

@media only screen and (max-width: 375px) {
  .service_mq_foot{
    margin-left: 106px;
  }
}

@media only screen and (max-width: 992px) {
  .media-space {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1.5rem;
  }

  .itemsfoot a li {
    line-height: 2.3;
  }

  .footer-img a img {
    /* display: flex; */
    /* align-items: start !important; */
    float: left;

  }

  .footer-img div {
    padding-top: 5rem;
  }

}

@media only screen and (max-width: 576px) {
  .banner-sec {
    padding: 10rem;
  }

  .header-two {
    font-size: 2rem !important;
  }
}
.corporate_background {
  display: flex;
  flex-direction: column;
}
.corporate_image {
  height: 100%;

  background: url(/static/media/expert.03a1b565.png) center rgba(255, 255, 255, 0.6)
    no-repeat;
  background-size: contain;
}
.trainer-container {
  background-color: "white";
}
.inputfieldsize {
  display: flex;
  justify-content: center;
  margin: 40px;
}
.textfieldsize {
  display: flex;
  justify-content: space-between;
  margin-left: 50px;
  margin-bottom: 20px;
}
.natalheading {
  color: rgba(60, 68, 111, 0.6);
  display: flex;
  justify-content: center;
}
.message_container {
  background-color: orange;
  word-break: break-word;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  padding: 20px;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 600;
  color: rgba(51, 51, 51, 0.8);
  font-family: "Roboto" !important;
}
.corporateplanheader {
  color: rgba(60, 68, 111, 0.6);
  display: flex;
  justify-content: center;
}
.MuiInputBase-input {
  font-weight: 500 !important;
  color: #D0A26E !important;
}
.MuiFormLabel-root {
  font-weight: 400 !important;
  color: #D0A26E !important;
  border-bottom: 1px solid #D0A26E !important;
}
.MuiFormLabel-root.Mui-focused {
  font-weight: 400 !important;
  color: #D0A26E !important;
}

@media only screen and (max-width: 320px) {
  .buttonsubmit {
    background-color: #E9C589;
    border-radius: 0.5rem;
    font-family: Arial, Helvetica, sans-serif;
    padding: 11px;
    top: 50%;
  }
}

@media only screen and (max-width: 320px) {
  .modal-dialog {
    /* height: -webkit-fill-available; */
  }
  .MuiInputBase-input {
    width: auto !important;
  }
}
@media only screen and (max-width: 321px) and (max-width: 769px) {
}

.readSuccessStoriesHeading {
  font-size: 50px;
  font-weight: bolder;
  color: #3c446f;
  line-height: 55px;
}
.ReadReviewparagragh {
  color: #3c446f;
  font-weight: 20px;
  margin-top: 15px;
}
.testheading {
  display: flex;
  align-items: center;
}
.testCard {
  display: flex;
  align-items: center;
  background-color: ghostwhite;
  border: 0 !important;
  justify-content: flex-start;
}
.testCardContent {
  justify-content: space-between;
  display: flex;
}
.testCardText {
  text-align: justify;
  color: #3c446f;
  margin-top: 0px;
  justify-content: flex-start;
  display: flex;
}

.testCardImg {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50% !important;
  border: 0px !important;
}
.quotationMark {
  font-size: 75px;
  color: #3c446f;
  margin-top: 0px;
}
.testCardName {
  color: #3c446f;
  font-size: 25px;
  justify-content: flex-start;
  display: flex;
}
.testCardOccupation {
  color: #3c446f;
  justify-content: flex-start;
  display: flex;
}
.testBtn {
  text-decoration: none;
  margin-top: 50px;
  background-color: #ccf381;
  color: #3c446f;
  display: inline-block;
  border: 0 !important;
  border-radius: 14px;
  padding: 16px 10px;
}

.testBtn:hover {
  font-weight: bold;
}
.dashboard-container {
  text-align: center;
}

.container-fluid .verticle-slider {
  width: 100%;
  background: ghostwhite;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.container-fluid .verticle-slider div.carousel-wrapper {
  height: 50vh;
  overflow: hidden;
}
div.container-fluid .verticle-slider div.carousel-wrapper .owl-carousel {
  height: 100%;
  transform: rotate(90deg);
  margin-top: 100px;
}
div.container-fluid .verticle-slider div.carousel-wrapper .owl-carousel .item {
  width: 400px;
  height: 400px;
  transform: rotate(-90deg);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
}
div.container-fluid
  .verticle-slider
  div.carousel-wrapper
  .owl-carousel
  .item
  > div {
  color: black;
}
/* CSS talk bubble */
.talk-bubble {
  margin: 40px;
  display: inline-block;
  position: relative;
  width: 200px;
  height: auto;
  background-color: lightyellow;
}
.border {
  border: 8px solid #666;
}
.round {
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
}

/* Right triangle placed top left flush. */
.tri-right.border.left-top:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -40px;
  right: auto;
  top: -8px;
  bottom: auto;
  border: 32px solid;
  border-color: #666 transparent transparent transparent;
}
.tri-right.left-top:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -20px;
  right: auto;
  top: 0px;
  bottom: auto;
  border: 22px solid;
  border-color: lightyellow transparent transparent transparent;
}

/* Right triangle, left side slightly down */
.tri-right.border.left-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -40px;
  right: auto;
  top: 30px;
  bottom: auto;
  border: 20px solid;
  border-color: #666 #666 transparent transparent;
}
.tri-right.left-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -20px;
  right: auto;
  top: 38px;
  bottom: auto;
  border: 12px solid;
  border-color: lightyellow lightyellow transparent transparent;
}

/*Right triangle, placed bottom left side slightly in*/
.tri-right.border.btm-left:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -8px;
  right: auto;
  top: auto;
  bottom: -40px;
  border: 32px solid;
  border-color: transparent transparent transparent #666;
}
.tri-right.btm-left:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 0px;
  right: auto;
  top: auto;
  bottom: -20px;
  border: 22px solid;
  border-color: transparent transparent transparent lightyellow;
}

/*Right triangle, placed bottom left side slightly in*/
.tri-right.border.btm-left-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 30px;
  right: auto;
  top: auto;
  bottom: -40px;
  border: 20px solid;
  border-color: #666 transparent transparent #666;
}
.tri-right.btm-left-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 38px;
  right: auto;
  top: auto;
  bottom: -20px;
  border: 12px solid;
  border-color: lightyellow transparent transparent lightyellow;
}

/*Right triangle, placed bottom right side slightly in*/
.tri-right.border.btm-right-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 30px;
  bottom: -40px;
  border: 20px solid;
  border-color: #666 #666 transparent transparent;
}
.tri-right.btm-right-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 38px;
  bottom: -20px;
  border: 12px solid;
  border-color: lightyellow lightyellow transparent transparent;
}
.tri-right.border.btm-right:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -8px;
  bottom: -40px;
  border: 20px solid;
  border-color: #666 #666 transparent transparent;
}
.tri-right.btm-right:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 0px;
  bottom: -20px;
  border: 12px solid;
  border-color: lightyellow lightyellow transparent transparent;
}
/* Right triangle, right side slightly down*/
.tri-right.border.right-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -40px;
  top: 30px;
  bottom: auto;
  border: 20px solid;
  border-color: #666 transparent transparent #666;
}
.tri-right.right-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -20px;
  top: 38px;
  bottom: auto;
  border: 12px solid;
  border-color: lightyellow transparent transparent lightyellow;
}

/* Right triangle placed top right flush. */
.tri-right.border.right-top:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -40px;
  top: -8px;
  bottom: auto;
  border: 32px solid;
  border-color: #666 transparent transparent transparent;
}
.tri-right.right-top:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -20px;
  top: 0px;
  bottom: auto;
  border: 20px solid;
  border-color: lightyellow transparent transparent transparent;
}

/* talk bubble contents */
.talktext {
  padding: 1em;
  text-align: left;
  line-height: 1.5em;
}
.talktext p {
  /* remove webkit p margins */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  background-color: whitesmoke;
  color: #3c446f;
  display: inline-block;
  border: none;
}
.scroll {
  height: 600px;
  overflow: hidden;
  overflow-y: scroll;
}
@media only screen and (max-width: 320px) {
  .testimonials {
    flex-direction: column !important;
  }
  .readSuccessStoriesHeading {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 321px) and (max-width: 426px) {
  .testimonials {
    flex-direction: column !important;
  }
  .readSuccessStoriesHeading {
    font-size: 1.5rem;
  }
}

#landing {
  background-position: center;
  position: relative;
  background-size: cover;
  min-height: 100vh;
  /* background: url('./../assests/Artboard.png') no-repeat; */
  background-position: center;
  background-size: cover;
}
.header-two{
  font-size: 75px;
  font-family: "Gehuropic-Regular";
  font-weight: 900;
  text-align: center;
  color: white;
}
.header-three{
  font-size: 35px;
  font-family: "Gehuropic-Regular";
  font-weight: 600;
  text-align: center;
  color: white;
}
.header-p{
   font-size: 18px;
    text-align: center;
    color: white;
    font-family: "Roboto";
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container > h2 > strong {
  color: #3c446f;
}
.container > h2 {
  margin: 40px;
}
.container-column {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}
.container-column-wrap {
  display: flex;
  flex-direction: column;
}
.container-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.nav {
  text-align: center;
}
.image-gif-main {
  background: url(/static/media/mobile.e0324c09.svg) no-repeat right;
  height: 35rem;
  width: 100%;
  right: 10%;
  position: absolute;
}
.image-gif {
  height: 25rem;
  display: flex;
  right: 0;
  transform: scaleX(-1);
}
.foot {
  background: rgb(0,0,0);
  margin-top: 4rem;
  color: white;
  margin: 0;
}
.items {
  list-style: none;
  cursor: pointer;
}
.items1 {
  list-style: none;
  margin-right: 2.5rem;
  cursor: pointer;
}
.foothead {
  margin-top: 4rem;
}
.items {
  list-style: none;
  margin-right: 2.5rem;
  cursor: pointer;
}
.button-slanted {
  transform: skewX(-20deg);
  border: 2px solid #D0A26E;
}
.button-slanted-two {
  transform: skewX(-20deg);
  border: 2px solid #D0A26E;
}

.button-slanted-content {
  transform: skewX(20deg);  
  display: inline-block;
}
.background-watermark {
  background: #000;
  background-image: url(/static/media/banner-back.85b77a04.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.gym-background {
  min-width: 100%;
  display: flex;
  flex-direction: column;
}
.home8 {
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
}
.home5 {
  margin-left: 6rem;
}
.home6 {
  line-height: 2rem;
  margin-left: 5rem;
}
.enroll_main {
  margin-top: 2rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.enroll_card {
  display: flex;
  justify-content: flex-end;
}
@keyframes example {
  0% {
    bottom: 0px;
    top: 200px;
  }
  100% {
    top: 0px;
    bottom: 200px;
  }
}
.enroll-now-container-diet {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  border-radius: 20px;
  margin: 10px 5px;
  box-shadow: 0px 0px 20px 1px #d8e2ef;
  cursor: pointer;
  font-family: Roboto !important;

}

.enroll-now-container-diet:hover {
  background: whitesmoke;
  transform: scale3d(2px);
}

.container-diet {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between !important;
  height: 100%;
  font-weight: 400;
  font-size: 16px;
}
.diet-bottom {
  width: 100%;
  background: #ccf381;
  border-radius: 0 0 20px 20px;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-evenly;
}
.enroll-now-container > img {
  width: 10vh;
  height: 10vh;
  transform: scaleX(-1);
}

.home10 {
  margin-top: 2rem;
}
.padding-10 {
  padding: 10px;
}

.requestFooter {
  margin: 0 !important;
  display: flex !important;
  justify-content: center !important;
  background: ghostwhite;
  border-radius: 0 0 20px 20px !important;
}
.list {
  list-style: none;
  width: 600px;
  max-width: 90%;
}
​ .item {
  display: block;
  clear: both;
  counter-increment: list;
  padding-bottom: 4rem;
  font-size: 1.1rem;
  line-height: 1.375;
  position: relative;
}
​ .item:before {
  font: bold 1.25rem/1 var(--font-number);
  content: counter(list);
  width: 2rem;
  height: 2rem;
  float: left;
  margin: 0 0.5rem 0.75rem 0;
  color: var(--bg);
  background: var(--highlight1)
    linear-gradient(to bottom right, var(--highlight1) 25%, var(--highlight2));
  text-shadow: 0 0 2px var(--highlight1);
  border-radius: 0 50% 50% 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  shape-outside: ellipse();
  z-index: 1;
}
​ .item:after {
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background: var(--highlight1);
  z-index: -1;
  border-top-left-radius: 3px;
}
​ ​ .headline {
  padding: 0rem 0 0 0;
  margin: 0 0 1rem 0;
  font: normal 2rem var(--font-head);
}
.enroll_label_header {
  font-size: 1.2rem;
  font-weight: bold;
  color: #bdb76c;
}
.form-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px !important;
}

.container-container-diet {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.requestformalignment {
  position: fixed;
  display: flex;
  z-index: 9999;
  justify-content: flex-end;
  bottom: 0;
  right: 2%;
}

.custom-map-modal {
  position: relative !important;
  top: 0%;
}
.custom-map-modal > .modal-content {
  border-radius: 4px 20px 20px 20px;
}
.iconsallignment {
  text-align: center;
  position: relative;
  -webkit-animation-name: example; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 4s; /* Safari 4.0 - 8.0 */
  animation-name: example;
  animation-duration: 4s;
  width: 15%;
  border-radius: 25px;
  background: rgba(189, 183, 108, 0.1);
}
.margin-0 {
  margin: 0 !important;
}
.padding-0 {
  padding: 0 !important;
}

.textAlign-center {
  text-align: center;
}
.bold {
  font-weight: 600;
}
.iconsallignment [class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  font-size: 60px;
  margin: 0;
}

.enroll-now-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  padding: 25px;
  border-radius: 20px;
  /* box-shadow: 10px 9px 16px #d8e2ef; */
  margin: 5px;
  border: 1px solid rgba(0, 128, 0, 0.2);
  font-family: Roboto !important;

}
.enroll_label {
  color: #3c446f;
  font-size: 18px;
  font-weight: bold;
}
.enroll_button {
  font-size: 17px;
  font-weight: 400;
  padding: 10px 18px;
  background-color: #E9C589;
  color: black;
  text-transform: uppercase;
  transform: skewX(-20deg);
}
.service_button {
  font-size: 17px;
  font-weight: 400;
  padding: 10px 18px;
  background-color: #000;
  color: #E9C589 !important;
  border: 1px solid #E9C589;
  text-transform: uppercase;
  transform: skewX(-20deg);
}
.enroll-content{
  transform: skewX(20deg);  
  display: inline-block;
}

.enroll_button:hover {
  padding: 12px 20px;
}
.iconText {
  font-size: 18px;
  font-weight: 500;
  color: slategrey;
}
.Howitworks_Header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background: rgba(50, 58, 82, 0.9);
  color: white;
  padding: 20px;
  border-radius: 4px 4px 0 0;
}
@media only screen and (max-width: 576px) {
  .Curated-Diet-sec{
    flex-direction: column-reverse;
    padding: 4rem 0;
  }
}
@media only screen and (max-width: 320px) {
  .dietplanheading {
    font-size: 1.5rem;
  }
  .header-three {
    font-size: 20px;
    font-family: "Gehuropic-Regular";
    font-weight: 600;
    text-align: start;
    color: white;
  }
  .header-two{
    font-size: 40px;
    font-family: "Gehuropic-Regular";
    font-weight: 600;
    text-align: start;
    color: white;
  }
  .header-p{
     font-size: 15px;
      text-align: start;
      color: white;
      font-family: "Roboto";
  }
  .app_inner input[type="radio"]:not(checked) + label .app_inner__tab {
    height: 42px;
    border-left: 12px solid rgba(117, 186, 49, 0.6);
  }
  .howitworksheading {
    font-size: 1.5rem;
  }
  .iconText {
    font-size: 15px;
    font-weight: 500;

  }
  .iconsallignment {
    display: table;
    width: 40%;
    margin: 10px;
  }
  .dietplanWidthContainer {
    width: 80%;
    max-width: 22vh;
  }
  .safehandsheading {
    font-size: 1.5rem;
  }
  .dietplanWidth {
    width: 100%;
  }
  .dietplans {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .requestformalignment {
    bottom: 0;
    right: 2%;
  }
  .iconsheading {
    font-size: 1.5rem;
  }
  .weightLiftingLady {
    display: block;
  }
  .tab_right {
    flex-direction: column;
  }
  .yogalady {
    display: none;
  }
  .requestformalignmentformobile {
    position: fixed;
    bottom: 0;
    right: 2%;
    display: flex;
    z-index: 9999;
    justify-content: flex-end;
  }
  .padding-10 {
    font-size: 14px;
    word-break: break-word;
  }
  .diet-bottom {
    justify-content: center;
  }
  .enroll_label_header {
    font-size: 1rem;
    font-weight: bold;
  }
  .enroll_card {
    display: flex;
    justify-content: flex-end;
  }
  .enroll_label {
    font-size: 14px;
  }
  .enroll_button {
    font-size: 17px;
  font-weight: 400;
  padding: 10px 18px;
  background-color: #E9C589;
  color: black;
  text-transform: uppercase;
  transform: skewX(-20deg);
  }
  .enroll-now-container {
    display: flex !important;
    background: white;
    padding: 40px;
    border-radius: 20px;
    /* box-shadow: 6px 5px 12px #d8e2ef; */
    margin: 5px;
    border: 1px solid rgba(0, 128, 0, 0.2);
    font-family: Roboto !important;

  }
}
@media only screen and (max-width: 375px) {
  .weightLiftingLady {
    display: none;
  }
  .header-three {
    font-size: 20px;
    font-family: "Gehuropic-Regular";
    font-weight: 600;
    text-align: start;
    color: white;
  }
  .header-two{
    font-size: 40px;
    font-family: "Gehuropic-Regular";
    font-weight: 600;
    text-align: start;
    color: white;
  }
  .header-p{
     font-size: 15px;
      text-align: start;
      color: white;
      font-family: "Roboto";
  }
  .dietplanheading {
    font-size: 1.5rem;
  }
  .app_inner input[type="radio"]:not(checked) + label .app_inner__tab {
    height: 42px;
    border-left: 12px solid rgba(117, 186, 49, 0.6);
  }
  .howitworksheading {
    font-size: 1.5rem;
  }
  .iconText {
    font-size: 15px;
    font-weight: 500;
  }
  .iconsallignment {
    display: table;
    width: 40%;
    margin: 10px;
  }
  .dietplanWidthContainer {
    width: 80%;
    max-width: 22vh;
  }
  .safehandsheading {
    font-size: 1.5rem;
  }
  .dietplanWidth {
    width: 100%;
  }
  .dietplans {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .requestformalignment {
    bottom: 0;
    right: 2%;
  }
  .iconsheading {
    font-size: 1.5rem;
  }
  .weightLiftingLady {
    display: none;
  }
  .tab_right {
    flex-direction: column;
  }
  .yogalady {
    display: none;
  }
  .requestformalignmentformobile {
    position: fixed;
    bottom: 0;
    right: 2%;
    display: flex;
    z-index: 9999;
    justify-content: flex-end;
  }
  .padding-10 {
    font-size: 14px;
    word-break: break-word;
  }
  .diet-bottom {
    justify-content: center;
  }
  .enroll_label_header {
    font-size: 1rem;
    font-weight: bold;
  }
  .enroll_card {
    display: flex;
    justify-content: flex-end;
  }
  .enroll_label {
    font-size: 14px;
  }
  .enroll_button {
    font-size: 17px;
    font-weight: 400;
    padding: 10px 18px;
    background-color: #E9C589;
    color: black;
    text-transform: uppercase;
    transform: skewX(-20deg);
  }
  .enroll-now-container {
    display: flex !important;
    background: white;
    padding: 1rem;
    border-radius: 20px;
    /* box-shadow: 6px 5px 12px #d8e2ef; */
    margin: 5px;
    border: 1px solid rgba(0, 128, 0, 0.2);
    font-family: Roboto !important;

  }
}
@media only screen and (max-width: 425px) {
  .weightLiftingLady {
    display: none;
  }
  .header-three {
    font-size: 20px;
    font-family: "Gehuropic-Regular";
    font-weight: 600;
    text-align: start;
    color: white;
  }
  .header-two{
    font-size: 40px;
    font-family: "Gehuropic-Regular";
    font-weight: 600;
    text-align: start;
    color: white;
  }
  .header-p{
     font-size: 15px;
      text-align: start;
      color: white;
      font-family: "Roboto";
  }
  .dietplanheading {
    font-size: 1.5rem;
  }
  .app_inner input[type="radio"]:not(checked) + label .app_inner__tab {
    height: 42px;
    border-left: 12px solid rgba(117, 186, 49, 0.6);
  }
  .howitworksheading {
    font-size: 1.5rem;
  }
  .iconText {
    font-size: 15px;
    font-weight: 500;
  }
  .iconsallignment {
    display: table;
    width: 40%;
    margin: 10px;
  }
  .dietplanWidthContainer {
    width: 80%;
    max-width: 22vh;
  }
  .safehandsheading {
    font-size: 1.5rem;
  }
  .dietplanWidth {
    width: 100%;
  }
  .dietplans {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .requestformalignment {
    bottom: 0;
    right: 2%;
  }
  .iconsheading {
    font-size: 1.5rem;
  }
  .weightLiftingLady {
    display: none;
  }
  .tab_right {
    flex-direction: column;
  }
  .yogalady {
    display: none;
  }
  .requestformalignmentformobile {
    position: fixed;
    margin-left: 150px;
    bottom: 2%;
    display: flex;
    z-index: 9999;
    justify-content: flex-end;
  }
  .padding-10 {
    font-size: 14px;
    word-break: break-word;
  }
  .diet-bottom {
    justify-content: center;
  }
  .enroll_label_header {
    font-size: 1rem;
    font-weight: bold;
  }
  .enroll_card {
    display: flex;
    justify-content: flex-end;
  }
  .enroll_label {
    font-size: 14px;
  }
  .enroll_button {
    font-size: 17px;
    font-weight: 400;
    padding: 10px 18px;
    background-color: #E9C589;
    color: black;
    text-transform: uppercase;
    transform: skewX(-20deg);
  }
  .enroll-now-container {
    display: flex !important;
    background: white;
    padding: 1rem;
    border-radius: 20px;
    /* box-shadow: 6px 5px 12px #d8e2ef; */
    margin: 5px;
    border: 1px solid rgba(0, 128, 0, 0.2);
    font-family: Roboto !important;

  }
}
@media all and (max-width: 767px) {
  .yogalady {
    display: none;
  }
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .iconsallignment {
    display: table;
    width: 37%;
    margin: 10px;
  }
  .header-three {
    font-size: 20px;
    font-family: "Gehuropic-Regular";
    font-weight: 600;
    text-align: left;
    color: rgb(208, 162, 110);
  }

  .requestformalignment {
    position: fixed;
    bottom: 0;
    right: 2%;
    display: flex;
    z-index: 9999;
    justify-content: flex-end;
  }
  .yogalady {
    justify-content: center;
    margin-top: 225px;
  }
  .dietplanWidthContainer {
    width: 80%;
    max-width: 26vh;
  }
  .dietplanWidth {
    width: 100%;
  }
  .dietplans {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

* {
  box-sizing: border-box;
}
i {
  font-style: normal;
}

.app_inner {
  position: relative;
}
.app_inner input[type="radio"] {
  display: none;
}
.app_inner input[type="radio"]:checked + label .app_inner__tab {
  height: 100%;
  padding: 40px 10px;
  background-color: #ccf381;
}
.app_inner input[type="radio"]:checked + label .app_inner__tab .tab_right {
  top: 39px;
  transition: all 0.3s 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.app_inner input[type="radio"]:not(checked) + label .app_inner__tab {
  height: 80px;
  border-left: 12px solid rgba(117, 186, 49, 0.6);
}
.app_inner input[type="radio"]:not(checked) + label .app_inner__tab .tab_right {
  top: 200px;
  transition: all 0.3s 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.app_inner
  input[type="radio"]:checked
  + label
  .app_inner__tab
  .tab_left
  .tab_left__image {
  animation: move_in 0.55s 0.05s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    forwards;
  transition: all 0.3s 0.36s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.app_inner
  input[type="radio"]:not(checked)
  + label
  .app_inner__tab
  .tab_left
  .tab_left__image {
  animation: move_out 0.75s 0s cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
  transition: all 0.3s 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.app_inner input[type="radio"]:checked + label .app_inner__tab .tab_left .big {
  left: 260px;
}
.app_inner
  input[type="radio"]:not(checked)
  + label
  .app_inner__tab
  .tab_left
  .big {
  left: 400px;
}
.app_inner input[type="radio"]:checked + label .app_inner__tab h2 i {
  opacity: 0;
}

.app_inner label {
  display: block;
  width: 100%;
  margin: 20px 0;
}
.app_inner label:nth-of-type(1) .app_inner__tab {
  background: white;
  height: 200px;
}
.app_inner label:nth-of-type(1) .app_inner__tab:hover {
  transition: all 0.2s;
  background: #ccf381;
}
.app_inner label:nth-of-type(2) .app_inner__tab {
  background: white;
}
.app_inner label:nth-of-type(2) .app_inner__tab:hover {
  transition: all 0.2s;
  background: #ccf381;
}

.app_inner label:nth-of-type(3) .app_inner__tab {
  background: white;
}
.app_inner label:nth-of-type(3) .app_inner__tab:hover {
  transition: all 0.2s;
  background: #ccf381;
}
.app_inner label:nth-of-type(4) .app_inner__tab {
  background: white;
}
.app_inner label:nth-of-type(4) .app_inner__tab:hover {
  transition: all 0.2s;
  background: #ccf381;
}
.app_inner label:nth-of-type(5) .app_inner__tab {
  background: white;
}
.app_inner label:nth-of-type(5) .app_inner__tab:hover {
  transition: all 0.2s;
  background: #ccf381;
}
.app_inner__tab {
  width: 100%;
  height: 80px;
  background: white;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  transition: all 0.65s cubic-bezier(1, 0, 0.41, 1.01);
}
.app_inner__tab h2 {
  position: absolute;
  color: darkslategray;
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
}
.app_inner__tab h2 i {
  position: absolute;
  right: 271px;
  opacity: 0.3;
  transition: all 0.3s;
}
.app_inner__tab .tab_right {
  width: 100%;
  float: right;
  position: relative;
  top: 200px;
  text-align: right;
  padding: 20px;
}
.app_inner__tab .tab_right h3,
.app_inner__tab .tab_right h4,
.app_inner__tab .tab_right p {
  margin: 0;
}
.app_inner__tab .tab_right h4 {
  font-size: 20px;
  margin: 4px 0px;
}
.app_inner__tab .tab_right p {
  font-size: 16px;
  color: #323a52;
  line-height: 30px;
}
.app_inner__tab .tab_right button {
  margin-top: 10px;
  border: 2px solid white;
  background: none;
  border-radius: 5px;
  padding: 6px 20px;
  cursor: pointer;
  font-family: "Roboto Condensed", sans-serif;
  outline: none;
  font-size: 24px;
  color: white;
  transition: all 0.3s;
}
.app_inner__tab .tab_right button:hover {
  color: black;
}
.app_inner__tab .tab_left {
  width: 30%;
  float: left;
  position: relative;
}
.app_inner__tab .tab_left .big {
  position: absolute;
  top: -17px;
  transition: all 0.3s 0.3s;
  left: 390px;
  font-size: 180px;
  opacity: 0.08;
}
.app_inner__tab .tab_left__image {
  position: absolute;
  text-align: center;
}

.app_inner__tab .tab_left__image img {
  width: 8rem;
  color: yellowgreen;
}

@keyframes move_out {
  0% {
    top: 47px;
  }
  100% {
    top: 200px;
  }
}
@keyframes move_in {
  0% {
    top: -200px;
  }
  100% {
    top: 47px;
  }
}
@keyframes bump {
  0% {
    top: 16px;
  }
  25% {
    top: 13px;
  }
  50% {
    top: 16px;
  }
  75% {
    top: 13px;
  }
  100% {
    top: 16px;
  }
}
@keyframes intro {
  0% {
    transform: translateY(-50%) scale(0) rotateX(10deg) rotateY(10deg)
      rotateZ(40deg);
  }
  100% {
    transform: translateY(-50%) scale(1) rotateX(0deg) rotateY(0deg)
      rotateZ(0deg);
  }
}
a.download,
a.follow {
  text-align: center;
  margin-top: 13px;
  width: 300px;
  float: left;
  display: inline-block;
  padding: 16px 30px;
  background: #ea495f;
  color: white;
  font-weight: 900;
  font-family: "Roboto Condensed", sans-serif;
  text-decoration: none;
  border-radius: 4px;
  margin-right: 12px;
}
a.download i,
a.follow i {
  margin-right: 10px;
}

a.follow {
  float: left;
  padding: 14px 30px;
  background: none;
  color: white;
  border: 2px solid white;
}
a.follow:hover {
  color: #41efb6;
  border-color: #41efb6;
}

/* carousel */

.carousel .slide {
  background-color: transparent !important;
}
.carousel .control-dots {
  position: relative !important;
}
.carousel .control-dots .dot {
  width: 14px !important;
  height: 14px !important;
  background-color: yellowgreen !important;
}
@media only screen and (max-width: 320px) {
  .app_inner__tab h2 {
    position: absolute !important;
    top: 16px !important;
    color: darkslategray !important;
    font-size: 14px !important;
    font-weight: bold !important;
  }
}
@media only screen and (min-width: 321px) and (max-width: 426px) {
  .app_inner__tab h2 {
    position: absolute !important;
    top: 16px !important;
    color: darkslategray !important;
    font-size: 14px !important;
    font-weight: bold !important;
  }
}

/*
  	Flaticon icon font: Flaticon
  	Creation date: 22/06/2016 15:01
  	*/

@font-face {
  font-family: "Flaticon";
  src: url(/static/media/Flaticon.593be869.eot);
  src: url(/static/media/Flaticon.593be869.eot?#iefix) format("embedded-opentype"),
    url(/static/media/Flaticon.730ba052.woff) format("woff"),
    url(/static/media/Flaticon.63caac29.ttf) format("truetype"),
    url(/static/media/Flaticon.ba309aec.svg#Flaticon) format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Flaticon";
    src: url(/static/media/Flaticon.ba309aec.svg#Flaticon) format("svg");
  }
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-size: 158px;
  font-weight: 600;
  margin-left: 20px;
  color: yellowgreen;
}

.fa-facebook-square .flaticon-4-weights-on-bar:before {
  content: "\f100";
}
.flaticon-abdominal-exercise:before {
  content: "\f101";
}
.flaticon-apple:before {
  content: "\f102";
}
.flaticon-apple-measure:before {
  content: "\f103";
}
.flaticon-bottle-opener:before {
  content: "\f104";
}
.flaticon-bottle-with-heart:before {
  content: "\f105";
}
.flaticon-dancer-outline:before {
  content: "\f106";
}
.flaticon-exercise-bike:before {
  content: "\f107";
}
.flaticon-flexible-man:before {
  content: "\f108";
}
.flaticon-footprints-on-a-scale:before {
  content: "\f109";
}
.flaticon-gymnast-posture:before {
  content: "\f10a";
}
.flaticon-heart-beats-controller-outline:before {
  content: "\f10b";
}
.flaticon-heart-pulses:before {
  content: "\f10c";
}
.flaticon-heart-pulses-1:before {
  content: "\f10d";
}
.flaticon-jar-of-food:before {
  content: "\f10e";
}
.flaticon-medical-diet-page:before {
  content: "\f10f";
}
.flaticon-meditation-yoga-posture:before {
  content: "\f110";
}
.flaticon-meter-spiral:before {
  content: "\f111";
}
.flaticon-pills-jar:before {
  content: "\f112";
}
.flaticon-reduce-waist:before {
  content: "\f113";
}
.flaticon-running-on-machine:before {
  content: "\f114";
}
.flaticon-shoe:before {
  content: "\f115";
}
.flaticon-skipping-rope:before {
  content: "\f116";
}
.flaticon-slipers:before {
  content: "\f117";
}
.flaticon-somersault-athlete:before {
  content: "\f118";
}
.flaticon-sportive-chronometer:before {
  content: "\f119";
}
.flaticon-stopwatch:before {
  content: "\f11a";
}
.flaticon-striped-ball:before {
  content: "\f11b";
}
.flaticon-strong-heart:before {
  content: "\f11c";
}
.flaticon-sweets-vs-fruits:before {
  content: "\f11d";
}
.flaticon-two-gym-dumbbells:before {
  content: "\f11e";
}
.flaticon-underwear:before {
  content: "\f11f";
}
.flaticon-warming-up:before {
  content: "\f120";
}
.flaticon-weight:before {
  content: "\f121";
}
.flaticon-weight-control:before {
  content: "\f122";
}
.flaticon-weights:before {
  content: "\f123";
}
.flaticon-whistles:before {
  content: "\f124";
}
.flaticon-women-summer-pijama:before {
  content: "\f125";
}
.flaticon-yin-yang:before {
  content: "\f126";
}
.flaticon-yoga-lotus-posture:before {
  content: "\f127";
}

.bg-header {
  width: 100%;
}
.logo-bg {
  background: #E9C589;
  border-radius: 0 50px 50px 0;
  margin: 10px;
  font-size: 3rem;
  color: #080808;
  padding: 10px !important;
  margin: 0;
  align-items: center;
  width: 100%;
  display: flex;
}
.dropdown-item {
  /* display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400; */
    color: #D0A26E !important;
    /* text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0; */
}
.dropdown-menu {
    color: #D0A26E !important;
    background-color: #000 !important;
}
.navbar { 
  padding: .5rem 1rem !important;
}
.navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
}

@media only screen and (max-width: 320px) {
  .logo-bg {
    font-size: 28px;
    padding: 5px;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  .navbar-light {
    display: flex;
    flex-direction: row-reverse;
  }
  .bowoot {
    font-size: 34px !important;
    padding: 5px !important;
  }
  .bowootheadericon {
    margin-right: 6px !important;
  }
}

@media only screen and (min-width: 321px) and (max-width: 375px) {
  .bowoot {
    font-size: 34px !important;
    padding: 5px !important;
  }
  .bowootheadericon {
    width: 2rem !important;
    margin-right: 6px !important;
  }
}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .bowoot {
    font-size: 34px !important;
    padding: 5px !important;
  }
  .bowootheadericon {
    /* width: 14rem!important; */
    margin-right: 6px !important;
  }
  .navbar-expand-lg .navbar-collapse {
    margin: 0px !important;
  }
}
.bowootheadericon {
  margin-right: 6px;
}
.bowoot {
  top: 0px;
  color: #080808;
  font-size: 2.4rem;
  margin: 0;
  display: flex;
  align-items: center;
}
.custom {
  display: flex !important;
  width: 100%;
  padding: 0 30px;
  flex-direction: row;
}

.home {
  font-family: "Fredoka One", cursive;
  font-size: 3.2rem;
  font-weight: 400;
}

.servie-link {
  font-weight: 500;
  font-size: 17px;
  text-transform: uppercase;
}

.dropdown > .dropdown-menu,
.show {
  border-radius: 14px !important;
}

.dropdown > .dropdown-menu,
.show > a {
  margin: 10px 0;
  text-align: center;
}

.custom-navbar-color {
  position: fixed !important;
  z-index: 999;
  background: #000 !important;
}
.home5 {
  text-align: left;
}

.navbar-light .navbar-nav .nav-link {
  color: #D0A26E !important;
  text-align: center;
}
.navbar-expand-lg .navbar-collapse {
  margin: 20px;
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .logo-bg {
    font-size: 2rem;
  }
  .bowoot {
    font-size: 1.4rem;
    padding: 0;
  }
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(233,197,137)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
}

.text-with-animation {
  font-size: 6vw;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  text-align: center;
  line-height: 100vh;
  vertical-align: middle;
  animation: main-text-animation 1.7s ease-in-out forwards;
  font-weight: 700;
  color: #aaf545;
  overflow: hidden;
  display: block;
  width: 500vw;
  margin-left: -200vw;
}

.splash-container {
  height: 100vh;
  line-height: 100vh;
  vertical-align: middle;
  background: linear-gradient(to bottom right, #080808 20%, #010101);
}

.subtext-with-animation {
  position: absolute;
  font-size: 2vw;
  text-align: center;
  line-height: 100vh;
  vertical-align: middle;
  top: 0;
  width: 100%;
  font-family: "Gochi Hand";
  color: #000;
  letter-spacing: 4px;
  -webkit-user-select: none;
          user-select: none;
  display: flex;

  justify-content: center;
}

.subtext-with-animation div:first-of-type {
  color: whitesmoke;
  display: none;
  position: relative;
  opacity: 0.4;
  animation: word-animation 0.5s ease-out 1.5s forwards;
}

.subtext-with-animation div:nth-of-type(2) {
  color: whitesmoke;
  display: none;
  position: relative;
  opacity: 0.4;
  animation: word-animation 0.5s ease-out 1.7s forwards;
}

body,
html {
  width: 100vw;
  max-width: 100vw;
  background: linear-gradient(to bottom right, #080808 20%, #040404);
}

@keyframes main-text-animation {
  0% {
    letter-spacing: 25vw;
    word-spacing: 100vw;
    opacity: 0;
    transform: translateY(0px);
  }
  100% {
    letter-spacing: 4px;
    word-spacing: 1vw;
    opacity: 1;
    transform: translateY(-15px);
  }
}

@keyframes word-animation {
  0% {
    top: 10vw;
    opacity: 0;
  }
  100% {
    top: 3.5vw;
    opacity: 1;
  }
}

.buttonalignment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.howitcomponent_button {
  font-size: 16px;
  font-weight: 600;
  padding: 10px 18px;
  border-radius: 4px;
  background-color: #aaf545;
  border-color: transparent;
  color: black;
  margin: 10px;
}

.alignheaderfitness {
  display: block;
  justify-content: space-between;
  width: 100%;
  font-size: 18px;
}
.descriptionaligment {
  display: flex;
  margin: 20px;
  text-align: justify;
}
.aligmentofsubparagraph {
  padding-right: 425px;
}
.institutecard {
  margin: auto;
  width: 75%;
}

.pricealignment {
  display: block;
  font-family: 'Roboto';
}
.marginbwprice {
  margin-left: 20px;
  font-family: Roboto !important;
}

.specialistfellowprogram {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-family: Roboto !important;
  text-align: justify;
}
.descriptionaligment1 {
  margin-right: auto;
  margin-top: 20px;
}
.descriptionaligment2 {
  margin-right: auto;
  margin-top: 20px;
}

.institute-modal {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 320px) {
  .pricealignment {
    display: block;
    flex-direction: row;
    justify-content: space-between;
    font-size: 15px;
    font-family: 'Roboto';
  }
  .headerfontsize {
    font-size: 15px;
  }
  .marginbwprice {
    margin-left: 0%;
    font-family: Roboto !important;
  }
}

.privacyContainer {
  text-align: justify;
font-family: "Roboto" !important;
  color: #E9C589;
}
.formarginbottom {
  margin-bottom: 2rem;
}

.topMargin {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.privacyImage {
  width: 20%;
  height: 20%;
  margin-top: 4rem;
}

@media only screen and (max-width: 320px) {
  .topMargin {
    flex-direction: column;
  }
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .topMargin {
    flex-direction: column;
  }
}

@media only screen and (max-width: 320px) {
  .privacyImage {
    width: 50%;
    margin-left: 70px;
  }
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .privacyImage {
    width: 40%;
    margin-left: 200px;
  }
}

@media only screen and (max-width: 320px) {
  .termsHeader {
    font-size: larger;
    font-weight: bolder;
    color: #E9C589;

  }
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .termsHeader {
    font-size: xx-large;
    font-weight: bolder;
  }
}
.TermsCenter {
  margin: 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .TermsCenter {
    margin: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.topParagraph {
  color: #E9C589;
  font-size: 1.5rem;
  width: 65%;
  text-align: justify;
  word-break: break-word;
  font-family: "Roboto";
}
@media only screen and (max-width: 320px) {
  .topParagraph {
    font-size: 15px;
    width: 100%;
    text-align: justify;
    word-break: break-word;
  }
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .topParagraph {
    font-size: 18px;
    text-align: justify;
    word-break: break-word;
    align-items: center;
  }
}
@media only screen and (max-width: 320px) {
  .termsIndiaHeading {
    font-size: large;
    text-align: center;
  }
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .termsIndiaHeading {
    text-align: center;
  }
}
@media only screen and (max-width: 320px) {
  .feedback {
    font-size: 14px;
  font-family: Roboto !important;
  color: #E9C589;
  }
}

.termsAndConditionsContainer {
  text-align: justify;
  color: #E9C589;
  font-family: "Roboto" !important;
}

.feedback {
  margin-bottom: 2rem;
  font-family: Roboto !important;
  color: #E9C589;
}
.topMargin {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.items a li {
  color: white;
}
@media only screen and (max-width: 320px) {
  .topMargin {
    flex-direction: column;
  }
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .topMargin {
    flex-direction: column;
  }
}
.termsandConditionsImage {
  width: 20%;
  height: 20%;
  margin-top: 4rem;
}
@media only screen and (max-width: 320px) {
  .termsandConditionsImage {
    width: 50%;
    margin-left: 70px;
  }
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .termsandConditionsImage {
    width: 40%;
    margin-left: 200px;
  }
}

@media only screen and (max-width: 320px) {
  .termsHeader {
    color: #E9C589;
    font-size: larger;
    font-weight: bolder;
  }
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .termsHeader {
    font-size: xx-large;
    font-weight: bolder;
  }
}
.TermsCenter {
  margin: 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .TermsCenter {
    margin: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.topParagraph {
  color: #E9C589;
  font-size: 1.5rem;
  width: 65%;
  text-align: justify;
  word-break: break-word;
  font-family: "Roboto";
}
@media only screen and (max-width: 320px) {
  .topParagraph {
    font-size: 15px;
    width: 100%;
    text-align: justify;
    word-break: break-word;
  }
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .topParagraph {
    font-size: 18px;
    text-align: justify;
    word-break: break-word;
    align-items: center;
  }
}
@media only screen and (max-width: 320px) {
  .termsIndiaHeading {
    font-size: large;
    text-align: center;
  }
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .termsIndiaHeading {
    text-align: center;
  }
}
@media only screen and (max-width: 320px) {
  .feedback {
    font-size: 14px;
  font-family: Roboto !important;
  color: #E9C589;
  }
}

.fa {
  padding: 0px;
  font-size: 25px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.fa-facebook-square {
  background: #3b5998;
  color: white;
}
.login {
  background-image: url(/static/media/banner-back.85b77a04.png);
  display: flex;
  justify-content: center;
  background-size: cover;
  flex-direction: column;
}
.login_background {
  background-size: contain;
  min-height: 70vh;
}

.login_signup_button {
  font-size: 20px;
  font-weight: 600;
  padding: 10px 18px;
  border-radius: 10px;
  background-color: #aaf545;
  border-color: transparent;
  color: black;
}

@media only screen and (max-width: 320px) {
  .fa-facebook-square {
    margin-left: auto;
  }
}
.fa-google-plus {
  background: red;
  color: white;
}

.NavItem {
  background-color: brown;
}
.fa-arrow-circle-right {
  font-size: 50px;
}

.fa-long-arrow-right {
  background: #E9C589;
  color: white;
}
.btn {
  border-block-end-style: none;
}

a {
  text-decoration: none !important;
}
.modal {
  text-align: center;
  padding: 0 !important;
  /* background-color: ghostwhite; */
}
.OrloginWith {
  text-align: center;
}
.modal-dialog {
  position: absolute;
  left: 50%;
  top: 40%;
  min-width: 25vw;
  transform: translate(-50%, -50%) !important;
}

.modal-content {
  align-items: center;
  background: black;
  border: 2px solid transparent;
}
@media only screen and (max-width: 320px) {
  .modal-container {
    width: 263px;
    margin-left: 20px;
  }
}
@media only screen and (max-width: 375px) {
  .modal-container {
    width: 2100px;
    margin-left: 40px;
  }
}
@media only screen and (max-width: 425px) {
  .modal-container {
    width: 2000px;
    margin-left: 55px;
  }
}

@media only screen and (min-width: 426px) and (max-width: 769px) {
  .modal-container {
    margin: auto;

    display: inline-flex;
    inline-size: -webkit-max-content;
    inline-size: max-content;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-height: -webkit-max-content;
    max-height: max-content;
    max-width: -webkit-max-content;
    max-width: max-content;
  }
}

.roundbtn {
  display: flex;
  justify-content: center;
  line-height: 0%;
}
.nav-tabs {
  display: flex;
  justify-content: space-between;
  width: auto;
}

.modal-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: black;
  border-radius: 20px;
  border: 0px solid;
  text-align: center;
}
@media only screen and (max-width: 320px) {
  .modal-container {
    width: 265px;
  }
}
.tabs {
  display: flex;
  justify-content: space-between;
  font-weight: bolder;
  font-size: 23px;
  color: lightgray;
}

.nav-link {
  cursor: pointer;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: 0 !important;
}
.editprofile {
  margin: auto;
  width: 70vh;
}
.editprofileHeader {
  color: rgba(60, 68, 111, 0.6);
}
.edit_allign {
  display: flex;
  align-items: flex-start;
}
.paddingeditcontainer {
  padding-right: 120px;
}
@media only screen and (min-width: 321px) and (max-width: 767px) {
  .login_background {
    display: none;
  }
}

.reactToastify{
    background-color: black;
    
}
.Toastify__close-button {
    color:white;
}
.background {
  color: white;
}

.userdashleftcontainer {
  background-color: white;
  min-height: 100vh;
  width: 100%;
  float: left;
}
.ongoingSessionHeader {
  color: rgba(60, 68, 111, 0.6);
}
.serviceheader {
  color: rgba(60, 68, 111, 0.6);
}
.dietPlanHeading {
  color: rgba(60, 68, 111, 0.6);
}

.avatarimg {
  border-radius: 50%;
  width: 150px;
  margin: 10px 85px;
}
.navvertical {
  display: flex;
  align-items: flex-start;
  flex-direction: column !important;
}
.navcollapse {
  /* text-align: center;  */
  /* flex-direction: column; */
  color: grey;
}
.navLink:hover {
  background-color: lightgray;
}
ul {
  list-style: none;
}
a {
  cursor: pointer;
}
.modal-container-userdash {
  border-radius: 10px;
  padding: 30px;
  box-shadow: 8px 9px 16px #d8e2ef;
}
.padding_user_dash_center {
  padding: 50px;
}
.ongoing_contents{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 600;
  
}
.ongoing_time{
  font-size: 14px;
}
@media screen and (max-width: 320px) {
  .avatarimg {
    display: none;
  }
  .profileComponent {
    display: none;
  }
  .profileDetails {
    display: none;
  }
  .userdashleftcontainer {
    background-color: initial;
  }
  .padding_user_dash_center {
    padding: 0px;
    max-width: 37vh;
  }
  .serviceheader {
    font-size: 22px;
  }
  .modal-container-userdash {
    padding: 0px;
    padding-top: 8px;
  }
  .ongoing_time {
    display: flex;
}
.ongoing_contents{
  padding: 5px;
}

}
@media only screen and (min-width: 321px) and (max-width: 426px) {
  .modal-container-userdash {
    padding: 0px;
    padding-top: 7px;
  }
  .font_ongoing{
    font-size: 24px !important;
  }
  .serviceheader {
    font-size: 24px;
}
.ongoing_contents{
  padding: 8px;
}

}

@media only screen and (min-width: 768px) {
  .avatarimg {
    display: none;
  }
  .profileComponent {
    display: none;
  }
  .profileDetails {
    display: none;
  }
  .padding_user_dash_center {
    padding: 6px;
  }
  .ongoing_contents{
    padding: 10px;
  }
}
.sidenav---sidenav---_2tBP {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1006;
  min-width: 64px;
  background: #080808 !important;
  transition: min-width 0.15s;
  width: 8%;
}

.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navtext---1AE_f {
  color: white;
}
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navtext---1AE_f {
  color: white;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL
  .sidenav---sidenav-subnav---1EN61
  > .sidenav---sidenav-subnavitem---1cD47
  > .sidenav---navitem---9uL5T {
  color: white;
}
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navtext---1AE_f {
  color: white;
}
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo
  > *,
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navtext---1AE_f
  > * {
  color: white;
}
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo
  > *,
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navtext---1AE_f
  > * {
  color: white;
}
.NavIcon {
  color: white;
}
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navtext---1AE_f {
  color: white;
}
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo
  > *,
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navtext---1AE_f
  > * {
  color: white;
}
@media only screen and (min-width: 426px) and (max-width: 768px) {
  .modal-container-userdash {
    padding: 0px;
    padding-top: 10px;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .dietPlanHeading {
    font-size: 20px;
  }
}

.accordion-Container {
  margin-top: 100px;
}
.profile-Container {
  margin-top: 200px;
}
.profile-Bucket {
  text-align: center;
}
.ongoingSessionContainer {
  background-color: #f5ac41;
  width: 100%;
}
.ongoingHeading {
  margin-left: 104px;
  padding: 10px;
}
.servicesContainer {
  background-color: white;
  min-height: 100vh;
  margin-top: 20px;
  width: 100%;
}
.dietplanContainer {
  background-color: white;
  margin-top: 20px;
  width: 100%;
}
.transactionHeading {
  color: rgba(60, 68, 111, 0.6);
}
.left_tab {
  display: flex;
  position: fixed;
  height: 100vh;
  padding-left: 0px;
}

.right_tab {
  width: 100%;
  padding-left: 0px;
  left: 25%;
}
.right_tab_cancel {
  left: 0px;
}
.userdashrow {
  padding-left: 0px;
}
.container-row-service {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.fa-chevron-down {
  color: gray;
  font-size: small;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: -8px;
}
.fa-chevron-up {
  color: gray;
  font-size: small;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: -16px;
}
.paymentId {
  float: right;
  padding-left: 100px;
}
.serviceDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.dot {
  height: 35px;
  width: 35px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  box-shadow: 8px 9px 16px #d8e2ef;
}
.accordionCss {
  background-color: transparent;
}
.diet_plan_details {
  background-color: ghostwhite;
  width: 100%;
  display: flex;
  margin: 20px 0;
  border-radius: 10px;
}
.diet_plan_contents {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  justify-content: space-between;
  border-radius: 10px;
}
.containerflex {
  display: flex !important;
  min-width: 100%;
  display: flex;
  flex-direction: column;
}
.sidebarposition {
  position: fixed !important;
  width: auto !important;
}
.profilenav {
  color: white !important;
}

.card-header {
  border: 0 !important;
}

.spinner-container {
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0px;
  left: 0;
  min-height: 100vh;
  pointer-events: none !important;
  z-index: 99999999999999;
  margin: 0 auto;
}
.spinner {
  display: flex;
  top: 50%;
  left: 44%;
  position: fixed;
  color: #E9C589;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
}
.diet-container-column {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-size: 16px;
}

.card-header {
  background: transparent;
}
.card {
  border: 0 !important;
}
.containerflex {
  display: flex !important;
}
.sidenav---sidenav-nav---3tvij {
  float: left;
  padding: 0;
  margin: 0;
  clear: both;
  list-style: none;
  display: flex;
  flex-direction: column;
}
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u
  > .sidenav---navitem---9uL5T {
  cursor: pointer;
}
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T {
  position: relative;
  display: block;
  line-height: 50px;
  height: 50px;
  white-space: nowrap;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  padding-top: 30px;
  padding-left: 6px;
}
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T::after {
  content: none !important;
}
.user_right_end_container {
  background-color: #f6f7fe;
  min-height: 100vh;
  right: 0;
}
.booksession_right_end {
  font-size: 16px;
  font-weight: 600;
  padding: 10px 18px;
  border-radius: 10px;
  background-color: #aaf545;
  color: black;
  max-width: -webkit-min-content;
  max-width: min-content;
  margin-top: 125px;
}
.booksession_right_end:hover {
  font-size: 22px;
}

.choose_Diet_right_end {
  font-size: 16px;
  font-weight: 600;
  padding: 10px 18px;
  border-radius: 10px;
  background-color: #aaf545;
  color: black;
  max-width: 25vh;
  margin-top: auto;
}
.choose_Diet_right_end:hover {
  font-size: 22px;
}
.border_design {
  /* border-left: 10px solid #aaf545; */
  box-shadow: none;
  padding: 0px;
  /* background: #f5f8f1; */
  border-radius: 4px;
  margin: 20px 5px;
  cursor: pointer;
  padding: 10px;
}
.bg_color {
  background-color: rgb(9, 20, 43, 0.9);
  font-weight: 400;
  color: white;
}
.book_diet_button {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.diet_content_width {
  width: 100%;
}
.cardheader {
  width: 100%;
}
.veiw_all_diet {
  float: right;
  margin-top: -30px;
}
.type_of_service {
  display: flex;
  flex-direction: column;
}
.trainer_package {
  color: black;
  border-radius: 10px;
  font-weight: bolder;
  background-color: #f5ac41;
  padding: 4px;
  display: flex;
  width: 100px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.service_display {
  display: flex;
  flex-direction: column;
  padding: 14px;
}
.service_date_time {
  font-size: 16px;
  color: #323a52;
  line-height: 30px;
}
.service_amount_paid {
  font-weight: 600;
  margin-left: auto;
  display: FLEX;
  flex-direction: column;
  align-items: flex-end;
  color: #323a52;
}
.display_contents {
  padding: 15px 30px 15px 15px;
  background-color: #fcfcfc;
  font-size: 14px;
}
.image_service {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.service_heading {
  font-weight: 400;
  color: #ccc;
}
.service_content_color {
  font-weight: 600;
  color: #abc;
}
.user_service_display_container {
  background: white;
  border-radius: 6px;
  box-shadow: 10px 9px 16px #d8e2ef;
  margin: 5px;
  border: 1px solid rgba(0, 128, 0, 0.2);
  margin-bottom: 30px;
}
.user_dash_service_trainer {
  background-color: #E9C589;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 6px 6px 0px 0px;
  padding: 10px;
  font-size: large;
  font-weight: 500;
  color: #323a52;
}
.service_package {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.service_font {
  font-size: large;
  color: #323a52;
  font-weight: 700;
}
.padding_service {
  padding: 20px 10px;
}
.price_user_dash {
  color: #323a52;
}
.font-size-amount {
  font-size: x-large;
  font-weight: bolder;
  padding: 10px 0px;
}
.trial {
  font-size: small;
}
.transaction_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px 5px 5px;
  color: #323a52;
}
.forothers_dash {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 320px) {
  .left_tab {
    position: initial;
  }
  .userdashrow {
    height: -webkit-max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
  }
  .right_tab {
    left: 0;
    width: 50vh;
  }
  .paymentId {
    padding-left: 0px;
    float: none;
  }
  .colum_reverse_Mq {
    flex-direction: column-reverse;
  }
  .user_right_end_container {
    background-color: transparent;
    min-height: 0vh;
  }
  .dietplan_user_dash_mq {
    display: none;
  }
  .blog_mq {
    display: none;
  }
  .book_diet_button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 0 65px;
  }
  .booksession_right_end {
    font-size: 14px;
    font-weight: bold;
    padding: 8px 14px;
    border-radius: 10px;
    background-color: #aaf545;
    color: black;
    max-width: -webkit-min-content;
    max-width: min-content;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .choose_Diet_right_end {
    font-size: 14px;
    font-weight: bold;
    padding: 8px 14px;
    border-radius: 10px;
    background-color: #aaf545;
    color: black;
    max-width: 25vh;
    margin-top: 0px;
  }
  .ongoingHeading {
    margin-left: 82px;
    padding: 0px;
  }
  .mq_service_display {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: -webkit-center;
  }
  .user_dash_service_trainer {
    flex-direction: column;
    font-size: small;
  }
  .transaction_container {
    flex-direction: column;
    align-items: end;
  }
  .service_amount_paid {
    /* margin-top: auto; */
    margin-left: 0px;
    align-items: flex-start;
  }
  .font-size-amount {
    font-size: medium;
    font-weight: 600;
  }
  .service_date_time {
    font-size: medium;
    line-height: normal;
  }
  .service_display {
    font-weight: 400;
    padding: 0px;
  }
  .service_package {
    justify-content: end;
  }
  .image_service {
    flex-direction: column;
  }
  .padding_service {
    flex-direction: column;
    align-items: flex-start;
  }
}
/* font-weight: 600;
  margin-left: auto;
  DISPLAY: FLEX;
  flex-direction: column;
  align-items: flex-end;
  color: #323a52; */
@media only screen and (min-width: 321px) and (max-width: 426px) {
  .border_design > .container-row-service {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .left_tab {
    position: initial;
  }
  .userdashrow {
    height: -webkit-max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
  }
  .right_tab {
    left: 0;
    width: 50vh;
  }
  .paymentId {
    padding-left: 0px;
    float: none;
  }
  .colum_reverse_Mq {
    flex-direction: column-reverse;
  }
  .user_right_end_container {
    background-color: transparent;
    min-height: 0vh;
  }
  .dietplan_user_dash_mq {
    display: none;
  }
  .blog_mq {
    display: none;
  }
  .book_diet_button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
  }
  .booksession_right_end {
    font-size: 16px;
    font-weight: bold;
    padding: 8px 14px;
    border-radius: 10px;
    background-color: #aaf545;
    color: black;
    max-width: -webkit-min-content;
    max-width: min-content;
    margin-top: 25px;
    margin-bottom: 18px;
  }
  .choose_Diet_right_end {
    font-size: 16px;
    font-weight: bold;
    padding: 8px 14px;
    border-radius: 10px;
    background-color: #aaf545;
    color: black;
    max-width: 25vh;
    margin-top: auto;
  }
  .ongoingHeading {
    margin-left: 32px;
    padding: 0px;
  }
  .mq_service_display {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: -webkit-center;
  }
  .user_dash_service_trainer {
    flex-direction: column;
  }
  .image_service {
    flex-direction: column;
  }
  .font-size-amount {
    padding: 50px 0px 0px 0px;
  }
  .transaction_container {
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0px 7px 20px;
  }
  .service_date_time {
    line-height: inherit;
  }
  .padding_service {
    padding: 20px 10px 0px 10px;
  }
  .service_package {
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 768px) {
  .userdashrow {
    height: -webkit-max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
    max-width: 100vh;
  }
  .right_tab {
    margin: auto;
  }
  .paymentId {
    padding-left: 0px;
    float: none;
  }
  .ongoingHeading {
    margin-left: 66px;
  }
  .bowoot {
    font-size: 40px;
  }
  .user_right_end_container {
    margin-left: auto;
  }
  .blog_mq {
    padding-left: 256px !important;
  }
}
@media only screen and (min-width: 426px) and (max-width: 768px) {
  .mq_service_display {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: -webkit-center;
  }
  .user_dash_service_trainer {
    flex-direction: column;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .book_diet_button {
    display: flex;
    flex-direction: column;
  }
  .booksession_right_end {
    margin-bottom: 12px;
  }
}

.inputfieldatfitnessform {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 20px;
  align-items: baseline;
}
.form-group-goal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 2rem !important;
  width: 200px;
}
.marginfitness {
  margin-bottom: 20px;
  background: #c5d0d661 !important;
}

.leftmarginfitness {
  margin-left: 15px;
}
.marginforbutton {
  margin-bottom: 100px;
}
.enroll_buttonforfitness {
  font-size: 20px;
  font-weight: 600;
  padding: 10px 18px;
  background-color: #E9C589;
  color: black;
}
.inputfieldatfitnessform > .btnColor {
  background: gainsboro;
}

.inHome,
.inGym,
.low,
.sedantary,
.moderate {
  background: #E9C589 !important;
  color: black !important;
}

.imagefitnessform {
  height: 250px;

  weight: 250px;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .imagefitnessform {
    display: none;
  }
}
@media only screen and (max-width: 320px) {
  .enroll_buttonforfitness {
    margin-bottom: 40px;
  }

  .radio label {
    font-size: 17px !important;
  }
}
@media only screen and (max-width: 375px) {
  .enroll_buttonforfitness {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 425px) {
  .enroll_buttonforfitness {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 768px) {
  .enroll_buttonforfitness {
    margin-bottom: 100px;
  }
  .workoutpreference {
    font-size: 15px;
  }
  .dailylifestyle {
    font-size: 15px;
  }
  .medicalcondition {
    font-size: 15px;
  }
  .wanttoacheievegoal {
    font-size: 15px;
  }
  .whendidulastworkout {
    font-size: 15px;
  }
  .marginformyself {
    flex: 0 0 50%;
    max-width: 100% !important;
  }
  /* .col-sm-6 {
    flex: 0 0 50%;
    max-width: 100% !important;
  } */
}

.fitnesscard {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px;
}
.fitnesstrainingbutton {
  color: yellowgreen;
  border-radius: 0rem;
  border: none;
  /* color: white;
  padding: 15px 32px;
  
  display: inline-block;
  font-size: 16px; */

  text-decoration: none;
  cursor: pointer;
}

.productclosetab {
  margin-left: 50px;
  cursor: pointer;
}

.btnmodal {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 15px 32px;

  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-right: 250px;
  cursor: pointer;
}
.knowyourbody {
  text-align: center;
}

.productsinfo {
  width: 40rem;
}

.howitworks {
  top: 170px;
  position: fixed;
  right: 0;
  z-index: 99;
  width: 500px;
}

.modal-dialog {
  top: 50% !important;
  left: 50% !important;
  position: fixed;
  width: 95%;
}
.enroll-now-container {
  display: flex;
  flex-direction: row;
  text-align: left;
  font-size: 14px;
  line-height: 1.5rem;
  word-break: break-word;
  font-family: Roboto !important;
}

.alignheaderfitness {
  display: block;
  justify-content: space-between;
  width: 100%;
}
.pricealignmentfitness {
  display: block;
  font-family: 'Roboto';
}

.marginbwpricefitness {
  margin-right: 2rem;
  font-family: Roboto !important;
}
.arrowSize {
  font-size: 15px !important;
  cursor: pointer !important;
}
.fitnesstraininginfobtn {
  background-color: white;

  border-radius: 4rem;
  border: none;
  /* border: none;

  padding: 15px 32px; */
  color: yellowgreen;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px 0px 480px;
  cursor: pointer;
}
.productclosetab {
  margin-left: 50px;
  cursor: pointer;
}
.dott {
  background-color: #E9C589;
  border-radius: 50%;
  display: flex;
  box-shadow: 8px 9px 16px #d8e2ef;
}

fa-arrow-right {
  height: 50px;
  width: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.modal-fitnessTraining {
  background-color: whitesmoke;
  width: 50px;
  height: 50px;
}
.priceandbuttonfitnesstraining {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #E9C589;
  border-radius: 0 0 20px 20px;

  padding: 0 10px 0px 10px;
}
.fitnesstrainingmaincontainer {
  display: "flex";
}

.diet_background {
  /* background: url("../assests/diet_landing.png") center no-repeat; */
  display: block;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 20%;
  background-size: 100%;
}
.diet_2_card_mq{
  margin-left: 128px;
  margin-top: 125px;
}
@media only screen and (max-width: 678px) {
  .howitworks {
    display: flex;
    flex-direction: column;
    position: static;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  .knowyourbody {
    font-size: 16px;
  }
  .productsinfo {
    width: 18rem;
  }
  .fitnessTrainingbtn {
    padding: 7px 16px !important;
    font-size: 14px !important;
    margin: auto !important;
  }
  .fitnesscard {
    width: 18rem;
  }
  .diet_2_img_mq {
    width: 40% !important;
    margin-right: 5px;
  }
}

@media only screen and (max-width: 769px) {
  .howitworks {
    display: flex;
    flex-direction: column;
    position: static;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: auto;
  }
  .diet_2_img_mq {
    width: 50% !important;
    margin-right: 10px;
  }
  .diet_2_card_mq {
    margin: auto;
  }
}

@media only screen and (max-width: 320px) {
  .howitworks {
    display: flex;
    flex-direction: column;
    position: static;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: auto;
  }
}
@media only screen and (min-width: 1440px) {
  .howitworks {
    top: 260px;
    position: fixed;
    right: 0;
    z-index: 99;
    width: 500px;
    margin-right: 40px;
  }
}

.close {
  color: #E9C589 !important;
  font-weight: 600;
  font-size: 32px;
}

.close:hover {
  color: #E9C589 !important;
}

.forminputfieldatyogaform {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 20px;
  align-items: baseline;
}
.alignmentofsubheaderyogaform {
  padding-right: 25px;
}
.inputfield-marginyoga {
  margin-bottom: 20px;
  background: #c5d0d661 !important;
}
.leftmarginyoga {
  margin-left: 15px;
}
.veg,
.nonveg,
.low,
.sedantary,
.moderate {
  background: #E9C589 !important;
  color: black !important;
}
.marginforyogabutton {
  margin-bottom: 100px;
}
.enroll_buttonforyoga {
  font-size: 20px;
  font-weight: 600;
  padding: 10px 18px;
  background-color: black;
  color: #E9C589;
}
.yogaformimage {
  height: 150px;
  width: 150px;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .yogaformimage {
    display: none;
  }
}

@media only screen and (max-width: 320px) {
  .enroll_buttonforyoga {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 375px) {
  .enroll_buttonforyoga {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 425px) {
  .enroll_buttonforyoga {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 768px) {
  .enroll_buttonforyoga {
    margin-bottom: 100px;
  }
  .preferreddiet {
    font-size: 15px;
  }
  .dailylifestyle {
    font-size: 15px;
  }
  .medicalcondition {
    font-size: 15px;
  }
  .marginformyselfyogaform {
    flex: 0 0 50%;
    max-width: 100% !important;
  }
}

.packagedetails {
  text-align: left;
  text-align: justify;
  margin: 15px;
}
.packagePlan {
  font-weight: bold;
  margin: 15px;
}
.packageSummary {
  text-align: center;
  font-weight: bold;
  margin: 15px;
  color: #E9C589 !important;
}
.packageamount {
  font-weight: bold;
  color: #E9C589 !important;
  font-family: Roboto !important;
}
.radioforpackage {
  display: flex;
  justify-content: center;
}
.packagebutton {
  display: flex;
  justify-content: space-between;
}
.packageMonth {
  display: flex;
  justify-content: space-between;
  color: #333;
  flex-direction: row;
  font-weight: 600;
  font-size: 14px;
  font-family: Roboto !important;
}
.servicegap {
  display: flex;
  flex-direction: row;
  margin: 10px;
  justify-content: space-between;
}
.servicecolor {
  color: #E9C589 !important;
  font-weight: bold;
}
.descriptiongap {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  color: #E9C589;
  font-family: Roboto !important;
}
.amountgap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
  color: #E9C589 !important;

}
.servicebold {
  font-weight: bold;
  color: #E9C589 !important;

}
.subtotal {
  font-weight: bold;
  color: #E9C589 !important;

}
.howitcomponent_buttoncancel {
  font-size: 16px;
  font-weight: 600;
  padding: 10px 18px;
  border-radius: 4px;
  background-color: #E9C589;
  border-color: transparent;
  color: black;
  margin: 10px;
}
.howitcomponent_buttonproceed {
  font-size: 16px;
  font-weight: 600;
  padding: 10px 18px;
  border-radius: 4px;
  background-color: #E9C589;
  border-color: transparent;
  color: black;
  margin: 10px;
}
.alignmentforradio {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
@media only screen and (max-width: 320px) {
  .howitcomponent_buttoncancel {
    display: flex;
    justify-content: space-between;
    width: auto;
    height: 50%;
  }
  .packagedetails {
    display: flex;
    width: 100%;
    height: 30%;
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 0px;

    padding: 0px;
  }
  .amountgap {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .servicegap {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .packageSummary {
    margin: 0px;
  }
  .modal-dialog {
    margin: auto;
  }
  hr {
    display: none;
  }
}
@media only screen and (max-width: 375px) {
  .packagedetails {
    display: flex;
    width: 100%;
    height: 30%;
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 50px;
    margin-right: 60px;
    padding: 0px;
  }
  .amountgap {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .servicegap {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .packageSummary {
    margin: 0px;
  }
  .modal-dialog {
    margin: auto;
  }
  hr {
    display: none;
  }
}
@media only screen and (max-width: 425px) {
  .packagedetails {
    display: flex;
    width: 100%;
    height: 30%;
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 50px;
    margin-right: 60px;
    padding: 0px;
  }
  .amountgap {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .servicegap {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .packageSummary {
    margin: 0px;
  }
  .modal-dialog {
    margin: auto;
  }
  hr {
    display: none;
  }
}

.productinfobtn {
  background-color: white;

  border-radius: 4rem;
  border: none;
  /* border: none;

  padding: 15px 32px; */
  color: yellowgreen;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px 0px 480px;
  cursor: pointer;
}
.productclosetab {
  margin-left: 50px;
  cursor: pointer;
}

.btnmodal {
  background-color: #4caf50;
  border: none;
  color: white;

  text-decoration: none;

  display: flex;
  justify-content: end;
  cursor: pointer;
}
.productsinfo {
  text-align: justify;
}
.alignheader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.pricealignment {
  display: block;
  justify-content: space-between;
  font-family: 'Roboto';
}
.marginbwprice {
  margin-right: 2rem;
  font-family: Roboto !important;
}

.arrowSize {
  font-size: 15px !important;
}
.product-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.productjsoncontainer {
  display: flex;
  flex-direction: column;
}
button {
  border: none;
  background: white;
}
.spacebwheaderbtn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  text-align: left;
}
.new_price {
  color: rgb(34, 41, 91);
}
.fa-chevron-down {
  color: gray;
  font-size: small;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: -16px !important;
}
.dottinproductinfo {
  background-color: #E9C589;
  border-radius: 50%;
  display: flex;
  position: absolute;
  bottom: 10px;
  right: 25px;

  box-shadow: 8px 9px 16px #d8e2ef;
}

.dottinproductinfo_toggle {
  background-color: white;
  border-radius: 50%;
  display: flex;
  margin-right: 60px;
  box-shadow: 8px 9px 16px #d8e2ef;
  font-size: 10px;
  padding: 10px;
}
@media only screen and (max-width: 320px) {
  .spaceoldnewprices {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .new_price {
    margin-left: -7px;
    color: rgb(34, 41, 91);
  }
  .dottinproductinfo {
    background-color: #E9C589;
    border-radius: 50%;
    display: flex;

    box-shadow: 8px 9px 16px #d8e2ef;
  }
  .arrowSize {
    font-size: 15px !important;
    width: 30px;
    height: 30px;
  }
}
@media only screen and (min-width: 321px) and (max-width: 375px) {
  .dottinproductinfo {
    background-color: #E9C589;
    border-radius: 50%;
    display: flex;

    box-shadow: 8px 9px 16px #d8e2ef;
  }
  .arrowSize {
    font-size: 15px !important;
    width: 30px;
    height: 30px;
  }
}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .dottinproductinfo {
    background-color: #E9C589;
    border-radius: 50%;
    display: flex;

    box-shadow: 8px 9px 16px #d8e2ef;
  }
  .arrowSize {
    font-size: 15px !important;
    width: 30px;
    height: 30px;
  }
}
@media only screen and (min-width: 426px) and (max-width: 768px) {
  .dottinproductinfo {
    background-color: #E9C589;
    border-radius: 50%;
    display: flex;

    box-shadow: 8px 9px 16px #d8e2ef;
  }
  .arrowSize {
    font-size: 15px !important;
    width: 30px;
    height: 30px;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .dottinproductinfo {
    background-color: #E9C589;
    border-radius: 50%;
    display: flex;

    box-shadow: 8px 9px 16px #d8e2ef;
  }
  .arrowSize {
    font-size: 15px !important;
    width: 30px;
    height: 30px;
  }
}
@media only screen and (min-width: 1250px) and (max-width: 1440px) {
  .dottinproductinfo {
    background-color: #E9C589;
    border-radius: 50%;
    display: flex;

    box-shadow: 8px 9px 16px #d8e2ef;
  }
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .mq_width {
    max-width: 100% !important;
  }
}

.marginHeaderDiet {
  margin-right: 20px;
  color: #E9C589 !important;
}
.PackageDetails_Header {
  background: black;
  color: #E9C589;
  padding: 20px;
  border-radius: 4px 4px 0 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.Acknowledgement_Header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background: black;
  color: #E9C589;
  padding: 20px;
  border-radius: 4px 4px 0 0;
}

.tickets_header {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 5px;
    font-size: x-large;
    color: gray;
  }
.ticket{
    background-color: transparent !important;
}
.ticke_container_margin{
    margin-left: 80px;
}
.book_radio {
  background: black;
  padding: 6px 14px;
  transform: skewX(-20deg);
  border: white 2px solid;
  margin: 10px;
  outline: none;
}
/* .book_session_radio label:hover {
    background-color: lightgray;
  } */
.book_session_radio input[type="radio"]:checked + label {
  border: solid 2px white;
  transform: skewX(-20deg);
  background: white;
  /* border-radius: 4px; */
}
.margin_radio {
  margin: 8px;
}
.table td,
.table th {
  border: none;
  font-family: Roboto !important;
  color: #E9C589;
}
.book_arrow {
  height: auto;
  width: auto;
  color: black !important;
}
.align_right {
  float: right;
}
.book_service_time {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.book_service_time > .input > .form-group {
  margin-bottom: 0px !important;
  /* margin: 8px; */
}
.Certified_trainer {
  display: flex;
  flex-direction: row;
  margin: 0px 14px;
  font-size: 16px;
  font-weight: 500;
}
.trainer_rupee {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  color: #D0A26E !important;
  align-items: center;
  font-family: Roboto !important;
}
.proceed_to_pay {
  align-items: center;
}
.proceed {
  background-color: black !important;
  color: #E9C589;
  border-radius: 0.5rem;
  font-family: Arial, Helvetica, sans-serif;
  width: 8rem;
  height: 3rem;
  top: 50%;
}

.txnid {
  font-family: Consolas, Monaco, Lucida Console, Liberation Mono,
    DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New;
}
.book_heading {
  padding: 10px;
  font-family: 'Roboto' !important;
    color: #E9C589;
}

.marginBottom_pick {
  margin: 20px 12px;
}
.pick_service_radio > .radio label {
  display: flex;
  align-items: center;
  font-family: Roboto !important;
  color: #E9C589;
}
.book_how_it_works {
  display: flex;
  flex-direction: column;
}
.book_index{
  min-height: 120vh;
}
@media screen and (max-width: 320px) {
  .booksession_container {
    /* width: 50% !important;
    margin-left: 98px !important;
    margin-top: inherit !important; */
    width: 57% !important;
    margin-left: 84px !important;
    margin-top: inherit !important;
  }
  .book_how_it_works {
    margin-right: 135px;
    padding-left: 25px;
  }
  .user_category_column {
    flex-direction: column;
  }
  .bookhowcontainer{
    width: 228px;
    /* width: 199px; */
    font-family: Roboto;
  }
  .proceed{
    width: -webkit-min-content ;
    width: min-content ;
    height: initial;
    font-size: small;
    flex-direction: column;
  }
}

@media only screen and (min-width: 321px) and (max-width: 375px) {
  .booksession_container {
    width: 65% !important;
    margin-left: 90px !important;
    margin-top: inherit !important;
    /* padding-right: 60px !important;
        padding-left: 0px ; */
  }
  .book_how_it_works {
    margin-right: 50px !important;
    margin-left: 10px;
  }
  .user_category_column {
    flex-direction: column;
  }
  .bookhowcontainer {
    margin-right: 38px;
    font-family: Roboto;

    /* width: 199px; */
  }
  .bookhowcontainer{
    width: 250px;
    font-family: Roboto;

  }
}
@media only screen and (min-width: 376px) and (max-width: 425px) {
  .booksession_container {
    width: 80% !important;
    margin-left: 45px !important;
    margin-top: inherit !important;
  }
  .book_how_it_works {
    margin-right: 100px !important;
  }
  .user_category_column {
    flex-direction: row;
  }
  /* .bookhowcontainer{
    width: 191px;
  } */
}
@media only screen and (min-width: 426px) and (max-width: 768px) {
  .user_category_column {
    flex-direction: row;
  }
  /* .book_index > .MQ_Tab_allign{
    max-width: 591px !important;
  } */
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  /* .booksession_container {
    margin-left: 50px !important;
  } */
  .book_how_it_works {
    margin-right: 100px !important;
  }
  /* .user_category_column {
    flex-direction: column;
  } */
}
/* @media (min-width: 992px) and (max-width:1440px) {
  .book_index > .container {
    max-width: 854px;
  }
} */

.logout-logo{
    width:30px;
    height:30px;
}

.nav-bar{
    background-color: white;
}

.blog-link{
    background-color:#C5F595 ;
    font-size: 18px;
    padding: 10px 25px 10px 25px;
    border-radius: 20px;
}

.admin-bowoot{
    text-align: center;
}

.admin-logo{
    top: 0px;
    bottom: 0px;
    margin-left: -20px;
    margin-right:24px;
    margin-top: -8px;
    margin-bottom: -8px;
    box-shadow: 0vh 0vh 1vh rgb(167, 164, 164);
    width:33vh;
}

.navbar-expand .navbar-nav{
    display: flex !important;
    flex-direction: row;
    justify-content: space-between !important;
    width : 100%;
}


.color{
    background-color: white;
    top:2px;
}

.session-div{
    background-color: rgb(250, 250, 250);
    font-size: 13px;
    padding:20px 10px 10px 10px;
    border-radius: 15px;
    color:rgb(148, 145, 145);
}

.session{
   padding:40px 7px 40px 20px;
   display: flex !important;
   flex-direction: row;
   justify-content: space-between !important;
   width : 100%;
}

.trainer-heading{
    padding-left: 20px;
    color:rgb(219, 216, 216) ;
}

.table-table, .table-head, .table-data{
    border: 1px black;
    padding:10px 10px 10px 10px;
    font-size:12px;
    color:rgb(148, 145, 145);
}

.table-table{
    width:20px;
}


.table-row{
    border-bottom: 1px solid rgb(219, 216, 216);
}

.right{
    text-align: right;
}

.right-column, .left-column{
    min-height:90vh;
}

.right-column{
    padding: 10px 0px 10px 10px;
}

.account-logo{
    padding:20px 30px 5px 30px;
    color:rgb(219, 216, 216);
}

.menu{
    padding:10px 10px 2px 60px;
    color:rgb(148, 145, 145);
    cursor: pointer;
}

.logo{
  background-color:#C5F595 ;
  padding:20px 30px 20px 30px;
  border-radius: 20px;
}

.logo-pad{
    padding:100px 20px 20px 70px;
}

.logo_image{
    width: 80px;
    height: 60px;
}

.version{
    letter-spacing: 2px;
    padding-left: 40px;
    font-weight: bold;
}

.render{
    padding:30px 30px 30px 30px;
}

.dashboard-logo{
    font-size: 25px;
    font-weight: bold;
    color: rgb(180, 171, 204);
}

.counts{
    padding:30px 0px 30px 30px;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between !important;
    width : 100%;
}

.row2{
    padding:0px 0px 30px 30px;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between !important;
    width : 100%;
}

.trainer-count{
    background-color: white;
    border-radius: 20px;
    padding:20px 20px 20px 20px;
    font-size:16px;
    color:rgb(148, 145, 145);
}

.request{
    background-color: white;
    border:0;
    box-shadow:0px 0px 2px rgb(219, 216, 216);
}

.space{
    font-weight: bold;
}

.trainer_name{
    color:black;
    padding-left: 40px;
}

.scrolls{
    min-height: 90vh;
}

.container-service {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between !important;
  }

  .hideButton{
    display: none;
}

#open{
    display:block;
}

#hidden{
    display:none;
}

.details-container {
    background: white;
    padding: 25px;
    border-radius: 20px;
    box-shadow: 10px 9px 16px #d8e2ef;
    margin: 5px;
    border: 1px solid rgba(0, 128, 0, 0.2);
  }

  .view-container{
      background-color: rgb(250, 250, 250);
      border:0;
  }

  .view-con{
    background-color: white;
    padding:20px 20px 20px 20px;
  }

  .padding-top{
    padding-top: 30px;
  }

  .star{
      display: inline;
      font-size: 14px;
  }

  .pd{
      padding-left:30px;
      color:rgb(148, 145, 145) ;
      font-family: 'roboto';
  }

  .right-side{
    display: flex !important;
    flex-direction: row;
    justify-content: space-between !important;
  }

  .wid{
      width:500px;
  }

  .wid1{
      width:800px;
  }

  .view-conn{
    background-color: white;
    padding:40px 50px 40px 50px;
  }

  .tdata{
      padding:20px 20px 20px 20px;
      background-color: rgba(247, 240, 238, 0.863);
      border-radius: 10px;
      font-size: 14px;
      font-family: 'roboto';
  }
  
  .btn-class{
    border-radius:20px;
    padding:4px 20px 4px 20px;
    color:white;
    font-size: 14px;
  }

  .table-service{
    width:1180px;
    color: black;
    font-size: 16px;
  }

  .right-data{
      text-align: right;
  }

  .service-row{
      border-bottom: 20px solid white;
      background-color: rgba(194, 203, 233, 0.253);
      padding:30px 30px 30px 30px;
  }

  .table-padding{
      padding:15px 20px 10px 20px;
  }

  .hidden{
    padding:0px 60px 0px 60px;
    min-width: 900px;
  }

@media screen and (min-width: 321px) and (max-width: 375px) {

    .hideButton{
        display: block;
    }

    #open{
        display:none;
    }

     .right-column{
         min-height: 0vh;
         padding:30px 30px 30px 30px;
     }
     .session{
         padding: 10px 10px 30px 15px;
     }

     .scrolls{
        min-height: 0vh;
    }
    
      .logo{
        padding:20px 30px 20px 30px;
        border-radius: 20px;
      }
      
      .logo-pad{
          padding:20px 20px 20px 100px;
      }
      
      .logo_image{
          width: 80px;
          height: 60px;
      }

      .container-service {
        padding-left: 40px;
        display: flex;
        flex-direction: column;
        text-align: center;
      }
      .trainer_name{
        padding-left: 0px;
    }

    .more{
        padding-top: 20px;
        padding-left: 75px;
    }

    .blogs-link{
        padding-top:14px;
        margin-left: -4vh;
    }

    .admin-logo{
        width:15vh;
    }

    .power_button{
        margin-left: -4vh;
        padding-top: 4px;
    }

 }

 @media only screen and (min-width: 376px) and (max-width: 425px) {
    .hideButton{
        display: block;
        text-align: right;
    }

    #open{
        display:none;
    }

     .right-column{
         min-height: 0vh;
         padding:50px 50px 50px 50px;
     }

     .session{
         padding: 10px 10px 30px 15px;
     }

     .scrolls{
        min-height: 0vh;
    }
    
      .logo{
        padding:20px 30px 20px 30px;
        border-radius: 20px;
      }
      
      .logo-pad{
          padding:20px 20px 20px 100px;
      }
      
      .logo_image{
          width: 80px;
          height: 60px;
      }

      .container-service {
        padding-left: 40px;
        display: flex;
        flex-direction: column;
        text-align: center;
      }
      .trainer_name{
        padding-left: 0px;
    }

    .more{
        padding-top: 20px;
        padding-left: 100px;
    }

    .blogs-link{
        padding-top:14px;
        margin-left: -4vh;
    }

    .admin-logo{
        width:15vh;
    }

    .power_button{
        margin-left: -4vh;
        padding-top: 4px;
    }
 }

 
 @media only screen and (min-width: 426px) and (max-width: 572px) {
    .hideButton{
        display: block;
        text-align: right;
    }

    #open{
        display:none;
    }

     .right-column{
         min-height: 0vh;
         padding:50px 50px 50px 50px;
     }

     table,th,td{
         font-size: 16px;
     }

     .session{
         padding: 10px 10px 30px 15px;
     }

     .scrolls{
        min-height: 0vh;
    }
    
      .logo{
        padding:20px 30px 20px 30px;
        border-radius: 20px;
      }
      
      .logo-pad{
          padding:20px 20px 20px 100px;
      }
      
      .logo_image{
          width: 80px;
          height: 60px;
      }

      .container-service {
        padding-left: 30px;
        display: flex;
        flex-direction: column;
        text-align: center;
      }
      .trainer_name{
        padding-left: 0px;
    }

    .more{
        padding-top: 20px;
        padding-left: 140px;
    }

    .blogs-link{
        padding-top:14px;
        margin-left: -4vh;
    }

    .admin-logo{
        width:15vh;
    }

    .power_button{
        margin-left: -4vh;
        padding-top: 4px;
    }
 }


 @media only screen and (min-width: 573px) and (max-width: 573px) {
    .hideButton{
        display: block;
        text-align: right;
    }

    #open{
        display:none;
    }

     .right-column{
         min-height: 0vh;
         min-width: 610px;
         padding:50px 50px 50px 50px;
     }

     table,th,td{
         font-size: 16px;
     }

     .session{
         padding: 10px 10px 30px 15px;
     }

     .scrolls{
        min-height: 0vh;
    }
    
      .logo{
        padding:20px 30px 20px 30px;
        border-radius: 20px;
      }
      
      .logo-pad{
          padding:20px 20px 20px 100px;
      }
      
      .logo_image{
          width: 80px;
          height: 60px;
      }

      .container-service {
        padding-left: 30px;
        display: flex;
        flex-direction: column;
        text-align: center;
      }
      .trainer_name{
        padding-left: 0px;
    }

    .more{
        padding-top: 20px;
        padding-left: 210px;
    }

    .blogs-link{
        padding-top:14px;
        margin-left: -4vh;
    }

    .admin-logo{
        width:15vh;
    }

    .power_button{
        margin-left: -4vh;
        padding-top: 4px;
    }
 }

.trainer_background {
  /* background: url("../assests/diet.png") no-repeat rgba(228, 241, 251, 0.1) */
    /* bottom; */
  background-size: contain;
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 100%;
  align-items: center;
}

.trainer_home {
  float: right;
  top: 10px;
  right: 10px;
  position: fixed;
  z-index: 55555;
  color: #7cc234;
  align-items: center;
}

.trainer_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: "white";
  justify-content: center;
  margin: 0 auto;
}

.trainer_header_container {
  background: black;
  color: #E9C589;
  padding: 0px;
  border-radius: 4px 4px 0 0;
}
.fitness_container {
  top: 45%;
  display: flex;
  position: absolute;
  left: 50%;
  flex-direction: column;
  width: 100%;
  transform: translate(-50%, -45%);
  background-color: "white";
}
.box-image {
  /* box-shadow: 10px 9px 16px #d8e2ef; */
  position: relative;
  background: rgb(0, 0, 0);
  display: flex;
  margin: 0;
  padding: 0;
  margin: 0 auto;
}

.typeButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.iconContainerRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.iconNext {
  font-size: 1.3rem;
  color: #D0A26E;
  float: right;
  margin: 20px 8px;
  background: black;
  border-radius: 50%;
  border: solid 2px;
}
.iconPrev {
  font-size: 1.3rem;
  color: #D0A26E;
  margin: 20px 8px;
  background: black;
  border-radius: 50%;
  border: solid 2px;

}
.buttonTrainerSelect {
  background: rgba(245, 245, 245, 0.3);
  padding: 14px;
  border: gray 2px solid;
  margin: 10px;
  border-radius: 4px;
  outline: none;
}

.buttonTrainerSelectActive {
  border: yellowgreen 3px solid;
  background: rgba(245, 245, 245, 0.3);
  padding: 14px;
  margin: 10px;
  border-radius: 4px;
  outline: none;
}

.buttontrainerselectactive:focus {
  outline: transparent !important;
}

.progressStyle {
  height: 6px !important;
  border-radius: 0px !important;
  background: grey;
}

.progress-bar {
  background: #E9C589 !important;
}

.shady_header {
  color: #E9C589;
  background: black;
  font-size: 40px 0;
}
.shady_header2 {
  font-size: 34px;
  color: #E9C589;
  background: black;
}
.shady_header3 {
  font-size: 20px;
  color: #E9C589;
  background: black;
}

.submitAllignment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.buttonsubmit {
  background-color: black;
  color: #D0A26E;
  font-family: Arial, Helvetica, sans-serif;
  width: 8rem;
  height: 3rem;
  top: 50%;
}
.button_date {
  background-color: white;
  border: solid 1px #E9C589 !important;
  font-family: Arial, Helvetica, sans-serif;
}
.MQ_allign {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 50px;
}
.black-background {
  background-color: #080808;
  color: yellowgreen;
  border-radius: 10px;
  padding: 10px;
  font-weight: 600;
}
.fit_howitworks {
  display: flex;
  flex-direction: column;
  transform: translate(18%);
}
.marginBottom {
  margin: 0 12px;
}
.fitnessChooseImage {
  width: 30%;
  position: fixed;
  bottom: 10px;
  right: 0;
}
.justify_content {
  justify-content: space-evenly;
}

.fitnesscontainer ul {
  list-style: none;
  font-family: "Roboto";
  color: black !important;
  padding: 15px;
}
.fitnesscontainer_two ul {
  list-style: none;
  font-family: "Roboto";
  color: #E9C589 !important;
  padding: 15px;
}

.fitnesscontainer ul li {
  position: relative;
  list-style: auto;
}
.fitnesscontainer_two ul li {
  position: relative;
  list-style: auto;
}

.fitnesscontainer ul li:before {
  margin: 8px;
  vertical-align: middle;
  display: none;
  width: 8px;
  height: 8px;
  background: #3e8232;
  content: "";
  margin-right: 16px;
  border-radius: 2px;
}
.fitnesscontainer_two ul li:before {
  margin: 8px;
  vertical-align: middle;
  display: none;
  width: 8px;
  height: 8px;
  background: #3e8232;
  content: "";
  margin-right: 16px;
  border-radius: 2px;
}

@media screen and (max-width: 320px) {
  .shady_header {
    font-size: 16px !important;
  }
  .buttonsubmitnext {
    width: -webkit-min-content;
    width: min-content;
    height: initial;

    font-size: small;
  }
  .buttonsubmit {
    width: -webkit-min-content !important;
    width: min-content !important;
    height: initial !important;
    font-size: small !important;
    flex-direction: column !important;
  }
  .fit_howitworks {
    margin-right: 88px !important;
  }
  .fitnessChooseImage {
    display: none;
  }
  .shady_header2 {
    font-size: 20px;
    color: rgba(60, 68, 111, 0.6);
    margin: 20px;
  }
}
@media only screen and (max-width: 375px) {
  .fit_howitworks {
    margin-right: 80px;
  }
}
@media only screen and (max-width: 425px) {
  .fit_howitworks {
    margin-right: 118px;
  }
}
@media only screen and (min-width: 321px) and (max-width: 426px) {
  .shady_header {
    font-size: 16px !important;
    text-align: center;
    margin-top: 60px;
  }
  .buttonsubmitnext {
    width: -webkit-min-content;
    width: min-content;
    height: initial;

    font-size: small;
  }
  .buttonsubmit {
    width: -webkit-min-content !important;
    width: min-content !important;
    height: initial !important;
    font-size: small !important;
    flex-direction: column !important;
  }
  .fitnessChooseImage {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .shady_header {
    font-size: 20px;
    text-align: center;
    margin-top: 60px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .shady_header {
    font-size: 20px;
    text-align: center;
    margin-top: 60px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .shady_header {
    font-size: 64px;
    text-align: center;
    margin-top: 60px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .shady_header {
    font-size: 64px;
  }
}
@media only screen and (max-width: 768px) {
  .trainer_box_mq {
    max-width: 75vh !important;
  }
  .shady_header {
    font-size: 40px;
  }
  .MQ_Tab_allign {
    max-width: 598px !important;
  }
  .fitnessChooseImage {
    width: 24%;
  }
}
@media only screen and (min-width: 770px) and (max-width: 1025px) {
  .trainer_box_mq {
    margin-top: 90px;
    max-width: 80vh;
  }
  .fitnesscontainer {
    margin-right: 55px;
  }
  .fitnesscontainer_two {
    margin-right: 55px;
  }
  .fitnessChooseImage {
    width: 23%;
  }
}
@media only screen and (min-width: 1026px) and (max-width: 1440px) {
  .fitnessChooseImage {
    width: 23%;
  }
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

section {
  margin: 100px 0 100px 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Metuo Personal Use-Regular" !important;
  font-size: 1rem;
  line-height: 1.42857143;
  color: #333;
  overflow-x: hidden;
  overflow-y: auto !important;
  background: whitesmoke;
}


@font-face {
  font-family: "Metuo Personal Use-Black";
  font-style: normal;
  font-weight: 900;
  src: url('https://anima-uploads.s3.amazonaws.com/projects/664e4c4c9c55ddf5654a5c88/fonts/metuopersonaluse-black.otf') format("opentype");
}

@font-face {
  font-family: "Gehuropic-Regular";
  font-style: normal;
  font-weight: 400;
  src: url('https://anima-uploads.s3.amazonaws.com/projects/664e4c4c9c55ddf5654a5c88/fonts/gehuropic.otf') format("opentype");
}

@font-face {
  font-family: "Metuo Personal Use-Regular";
  font-style: normal;
  font-weight: 400;
  src: url('https://anima-uploads.s3.amazonaws.com/projects/664e4c4c9c55ddf5654a5c88/fonts/metuopersonaluse-regular.otf') format("opentype");
}

@font-face {
  font-family: "Metuo-Regular";
  font-style: normal;
  font-weight: 400;
  src: url('https://anima-uploads.s3.amazonaws.com/projects/664e4c4c9c55ddf5654a5c88/fonts/metuopersonaluse-regular.otf') format("opentype");
}

@font-face {
  font-family: "Metuo-Bold";
  font-style: normal;
  font-weight: 700;
  src: url('https://anima-uploads.s3.amazonaws.com/projects/664e4c4c9c55ddf5654a5c88/fonts/metuopersonaluse-bold.otf') format("opentype");
}

@font-face {
  font-family: "Metuo Personal Use-Bold";
  font-style: normal;
  font-weight: 700;
  src: url('https://anima-uploads.s3.amazonaws.com/projects/664e4c4c9c55ddf5654a5c88/fonts/metuopersonaluse-bold.otf') format("opentype");
}

a {
  color: black;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
  color: #000000;
}
#root {
  min-height: 100vh;
  background-color: black;
  overflow-x: hidden;
}

:root {
  --bg: #fdfdfd;
  --highlight1: #ed4264;
  --highlight2: #ffedbc;
  --color: #1a1e24;
}

input {
  border: 0 !important;
  border-radius: 10px !important;
}

input[type="file"] {
  max-width: 31vh;
}

button:focus {
  outline: transparent;
}

label {
  color: rgba(34, 41, 91, 0.8);
  font-size: 18px;
  display: flex;
  align-items: center;
  text-align: left;
}

ul {
  margin: 0;
  padding: 0;
}

.radio label {
  display: flex;
  margin-bottom: 0.5rem;
  align-items: center;
  color: #D0A26E;
}
.radio input[type="radio"] {
  position: relative;
  opacity: 0;
}

.radio input[type="radio"] + .radio-label:before {
  content: "";
  background: ghostwhite;
  border-radius: 100%;
  border: 6px solid #D0A26E !important;
  display: inline-block;
  width: 1.2em;
  height: 1.2em;
  position: relative;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
  margin: 6px;
  font-family: Roboto !important;
}
.radio input[type="radio"]:checked + .radio-label:before {
  background-color: #E9C589;
  box-shadow: inset 1 1 1 4px #D0A26E !important;
  font-family: Roboto !important;

}
.radio input[type="radio"]:focus + .radio-label:before {
  outline: none;
  border-color: #D0A26E !important;
  font-family: Roboto !important;

}

.radio input[type="radio"] + .radio-label:empty:before {
  margin-right: 0;
  font-family: Roboto !important;

}

.book_radio {
  background: black;
  padding: 6px 14px;
  border: white 2px solid;
  margin: 10px;
  color: white;
  transform: skewX(-20deg);
  outline: none;
}

.book_session_radio input[type="radio"] {
  display: none;
}

.book_session_radio input[type="radio"]:checked + label {
  border: solid 2px white;
  background: white;
  margin: 10px;
  transform: skewX(-20deg);
  /* border-radius: 4px; */
  color: black;
  outline: none;
}

p {
 font-family: roboto !important;
}

.inputfield {
  width: 100% !important;
}

input[type="file"] {
  margin-left: 24px;
  display: inline-flex;
}

.scrollbar::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}
/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
  background-color: #fff;
}
.scrollbar::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}
/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 5px solid #fff;
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: darkgreen !important;
  border: 4px solid #f4f4f4;
}
/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {
  display: none;
}
.scrollbar {
  width: 25vw;
  min-width: 150px;
  background: #fff;
  overflow-y: scroll;
}
.overflow {
  min-height: 100vh;
  border-color: #000000;
  margin-bottom: 2px;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #080808 !important;
}

[type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}

[type="file"] + label {
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Metuo Personal Use-Regular";
  font-size: inherit;
  font-weight: 600;
  margin-bottom: 1rem;
  outline: none;
  padding: 4px 10px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
}
[type="file"] + label.btn-1 {
  transition: none;
}
[type="file"] + label.btn-1:hover {
  box-shadow: 0 4px;
  top: 2px;
}
[type="file"] + label.btn-2 {
  background-color: gray;
  border-radius: 50px;
  overflow: hidden;
}
[type="file"] + label.btn-2::before {
  color: #fff;
  font-family: "Font Awesome 5 Pro";
  font-size: 100%;
  height: 100%;
  line-height: 3.3;
  position: absolute;
  top: 0px;
  transition: all 0.3s;
}
[type="file"] + label.btn-2:hover {
  background-color: #080808;
}
[type="file"] + label.btn-2:hover::before {
  right: 75%;
}
[type="file"] + label.btn-3 {
  background-color: #497f42;
  border-radius: 0;
  overflow: hidden;
}
[type="file"] + label.btn-3 span {
  display: inline-block;
  height: 100%;
  transition: all 0.3s;
  width: 100%;
}
[type="file"] + label.btn-3::before {
  color: #fff;
  content: "";
  font-family: "Font Awesome 5 Pro";
  height: 100%;
  left: 0;
  line-height: 2.6;
  position: absolute;
  top: -180%;
  transition: all 0.3s;
  width: 100%;
}
[type="file"] + label.btn-3:hover {
  background-color: #ae144f;
}
[type="file"] + label.btn-3:hover span {
  transform: translateY(300%);
}
[type="file"] + label.btn-3:hover::before {
  top: 0;
}
.form-control {
  font-family: Roboto !important;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #D0A26E !important;
  background-color: #000 !important;
  background-clip: padding-box;
  border-bottom: 1px solid #D0A26E !important;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.react-datepicker-wrapper {
  width: 100%;
}
@media only screen and (max-width: 320px) {
  body {
    font-size: 14px !important;
  }
  .radio label {
    font-size: 14px;
  }
  section {
    margin: 0px;
  }
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  section {
    margin: 0px;
  }
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #aaf545;
  fill: #aaf545;
  animation: stroke 2s cubic-bezier(0.65, 0, 0.45, 1) forwards infinite;
}
.form-group div{
  /* width: 100%; */
}
.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 6;
  stroke: #464d63;
  stroke-miterlimit: 10;
  margin: 10px auto;
  box-shadow: inset 0px 0px 0px #ccf381;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke infinite 2s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
.dropdown-item:hover{
  color: #000 !important;
  text-decoration: none;
  background-color: #D0A26E;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

